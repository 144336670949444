.nav-bar {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--primary-tint-color);
  height: 100dvh;
  width: 275px;
}

.nav-title {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  user-select: none;
  transition: 0.2s ease-in-out background-color;
  border-radius: 0.75rem;
  cursor: pointer;
}

.nav-title .logo {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 6px;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: var(--primary-tint-color);
  color: var(--primary-font-color);
  font-size: 1.25rem;
}

.nav-title .logo.with-image {
  background-color: transparent !important;
  border: 1px solid var(--primary-tint-color) !important;
}

.nav-title .program-name {
  font-size: 1.15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}

.nav-menu-items {
  display: flex;
  flex-direction: column;
  font-size: 1.35rem;
  flex: 1;
}

.nav-menu-items .nav-label:first-child {
  margin-top: 1rem;
}

.nav-menu-items .nav-label {
  margin-top: 2rem;
}

.nav-menu-items a {
  padding: 0.5rem 0.75rem;
  display: block;
  text-align: left;
  width: 100%;
  color: var(--primary-font-color);
  text-decoration: none;
  user-select: none;
  background-color: transparent;
  transition: background-color 0.12s ease-in-out, opacity 0.12s ease-in-out;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1.15rem;
}

.nav-menu-items a:hover,
.nav-profile-container a:hover,
.nav-title:hover {
  cursor: pointer;
  background-color: var(--primary-tint-color);
}

.nav-menu-items a svg {
  margin-right: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.nav-menu-items a.active,
.nav-profile-container a.active {
  background-color: var(--primary-tint-color);
  opacity: 1;
}

.nav-profile-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-profile-container a {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.25rem;
  color: var(--primary-font-color);
  text-decoration: none;
  font-weight: bold;
  user-select: none;
  padding: 0.5rem;
  background-color: transparent;
  transition: background-color 0.12s ease-in-out, opacity 0.12s ease-in-out;
  border-radius: 15px;
}

.nav-avatar {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  background-color: var(--avatar-background-color);
  text-align: center;
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.nav-profile-name {
  font-size: 1.15rem;
  font-weight: normal;
}

.nav-profile-container.mobile {
  display: none;
}

.nav-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.nav-bar-mobile {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-update {
  font-size: 1rem !important;
  padding: 0.75rem;
  border: 1px solid var(--primary-tint-color);
  border-radius: 5px;
  margin: 0.5rem;
  margin-bottom: 1rem;
}

.feature-update a {
  font-size: 1rem;
  display: inline;
  padding: 0;
  text-decoration: underline;
  color: var(--primary-font-color);
}

.feature-update .button {
  font-size: 0.9rem;
  padding: 0.25rem;
  margin-top: 1rem;
}

.feature-update-content {
  opacity: 0.75;
}

@media (max-width: 750px) {
  .nav-title .logo {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    font-size: 1rem;
  }

  .nav-bar.global {
    padding: 0;
    display: block;
    border-right: none;
    height: auto;
    width: auto;
  }

  .nav-bar.settings {
    height: auto;
    border-right: none;
    border-bottom: 1px solid var(--primary-tint-color);
    padding: 0;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: var(--primary-background-color);
    z-index: 10;
  }

  .nav-bar.global .nav-bar-mobile {
    height: auto;
    border-right: none;
    border-top: 1px solid var(--primary-tint-color);
    padding: 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: var(--primary-background-color);
    z-index: 10;
    left: 0;
  }

  .app-standalone-ios .nav-bar-mobile {
    padding-bottom: 1.5rem !important;
  }

  .home-page .nav-bar.global .nav-title {
    display: flex;
    margin-bottom: 0;
    border-radius: 0;
    position: absolute;
    top: 0.25rem;
    left: 0;
    padding-left: 1rem;
    width: 100%;
    background-color: var(--primary-background-color);
  }

  .nav-bar.global .nav-title {
    display: none;
  }

  .nav-bar .nav-title:hover {
    background-color: var(--primary-background-color);
  }

  .nav-menu-items {
    flex-direction: row;
    text-align: center;
  }

  .nav-menu-items a {
    flex-direction: column;
    font-size: 0.85rem;
    padding: 0.25rem;
    opacity: 0.5;
    transition: opacity 0.12s ease-in-out;
  }

  .nav-menu-items a.active {
    background-color: transparent;
  }

  .nav-menu-items a svg {
    margin-right: 0;
  }

  .nav-avatar {
    margin-right: 0;
    height: 1.75rem;
    width: 1.75rem;
    min-width: 1.75rem;
    min-height: 1.75rem;
    font-size: 0.9rem;
  }

  .nav-profile-name {
    font-size: 0.85rem;
  }

  .nav-profile-container.mobile {
    display: block;
  }

  .nav-profile-container {
    display: none;
  }

  .nav-menu-items a:hover,
  .nav-profile-container a:hover {
    background-color: transparent;
  }
}
