.pac-container {
  background-color: var(--input-background-color);
  border-radius: 15px;
  padding: 0.25rem;
  border: 1px solid var(--secondary-tint-color);
  z-index: 10000000;
}

.pac-icon {
  color: var(--primary-font-color);
}

.pac-item {
  color: var(--primary-font-color);
  border-top: none;
  border-bottom: 1px solid var(--secondary-tint-color);
}

.pac-item-query {
  color: var(--primary-font-color);
}

.pac-item:hover {
  background-color: var(--secondary-tint-color);
  border-radius: 15px;
  cursor: pointer;
}
