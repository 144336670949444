.app-settings .nav-back {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  user-select: none;
  transition: 0.2s ease-in-out background-color;
  border-radius: 0.75rem;
  cursor: pointer;
  margin-bottom: 1rem;
  width: fit-content;
  padding-right: 0.75rem;
  font-weight: bold;
  font-size: 1.15rem;
  margin-top: 0.3rem;
}

.app-settings .dropdown {
  top: 7.5rem;
}

.app-settings .nav-title {
  margin-bottom: 2rem;
}

.app-settings .nav-back svg {
  width: 1rem;
  margin-right: 0.5rem;
}

.app-settings .nav-back:hover {
  background-color: var(--primary-tint-color);
}

@media (max-width: 750px) {
  .app-settings .nav-back {
    display: flex;
    margin-bottom: 0;
  }

  .app-settings .nav-label {
    display: none;
  }

  .app-content.app-settings-content {
    padding-top: 5rem;
  }

  .app-content .org-nav-settings {
    margin-top: 0rem;
  }
}
