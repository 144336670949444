.playlist-container {
  display: flex;
  gap: 24px;
  height: 100%;
  max-width: 1000px;
  width: 100%;
  margin-top: 2rem;
}

.selector-wrapper {
  width: 300px;
  flex-shrink: 0;
  background-color: var(--primary-background-color);
}

.content-wrapper {
  flex: 1;
  min-width: 0;
  max-width: 700px;
  margin: 0 auto;
}

@media (max-width: 1000px) {
  .playlist-container {
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;
    position: relative;
  }

  .selector-wrapper {
    width: 100%;
  }

  .content-wrapper {
    margin-bottom: 300px;
    width: 100%;
  }

  .playlist-container::-webkit-scrollbar {
    display: none;
  }
}
