.checkin-prompt-container {
  margin-top: 1rem;
}

.scale-range-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  border: 1px solid var(--secondary-tint-color);
  border-radius: 0.75rem;
  padding: 0.5rem;
  background-color: var(--primary-background-color);
  height: 2.95rem;
}

.scale-item-selected:hover,
.scale-item:hover {
  cursor: pointer;
  opacity: 0.6;
}

.scale-item-selected {
  color: var(--primary-action-color);
}

.form-error {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
}

.submit-button {
  display: flex;
  padding: 0.5rem 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid #009688;
  font-weight: bold;
  text-decoration: none;
  user-select: none;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  transition: 0.2s opacity ease;
  cursor: pointer;
  background-color: #009688;
}

.submit-button:hover {
  opacity: 0.6;
}
