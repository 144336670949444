.pathway-container {
  max-width: 700px;
  width: 100%;
  margin: auto
}

h1 {
  color: var(--primary-font-color);
  font-size: 1.5rem;
  margin: 0;
}