.event-tag-dropdown {
  position: absolute;
  z-index: 10000000;
  background-color: var(--primary-background-color);
  border-radius: 15px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid var(--primary-font-color);
  top: 25px;
  right: -15px;
}

.event-tag-dropdown .MuiMenuItem-root {
  margin: 0.25rem !important;
  border-radius: 15px !important;
}

.event-tag-dropdown .MuiMenuItem-root .MuiListItemText-root .MuiTypography-root {
  font-size: 14px !important;
}

.event-tag-dropdown .MuiMenuItem-root:hover {
  background-color: var(--container-background-color) !important;
}

.border-container {
  border: 1px solid var(--primary-font-color);
  border-radius: 15px;
}

.filter-checkbox {
  color: var(--primary-font-color) !important;
}

.filter-checkbox:checked {
  color: var(--primary-action-color) !important;
}

.filter-events-container:hover {
  opacity: 0.6;
}

@media (max-width: 750px) {
  .event-tag-dropdown {
    right: -27px;
  }
}