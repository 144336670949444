.MuiAccordion-root {
  background-color: var(--container-background-color) !important;
  color: var(--primary-font-color) !important;
  max-width: 700px;
  margin: auto !important;
  margin-top: 1rem !important;
  border-radius: 15px !important;
}

.MuiAccordion-root.first {
  margin-top: 0.5rem !important;
}

.MuiAccordion-root::before {
  background-color: transparent !important;
}

.MuiAccordionSummary-expandIconWrapper {
  color: var(--primary-font-color) !important;
}

.MuiCheckbox-root:hover {
  color: var(--primary-action-color) !important;
  opacity: 0.6 !important;
}

.Mui-checked {
  color: var(--primary-action-color) !important
}

.action-description {
  word-break: break-word;
}

.uncompleted-action-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  color: var(--primary-font-color);
  align-items: center;
}

.uncompleted-action-container svg {
  color: var(--primary-font-color);
}

.check-settings-container {
  display: flex;
}

@media (max-width: 700px) {
  .action-description {
    margin-right: 0.25rem;
  }
}