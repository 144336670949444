.video-player-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.video-player-container iframe,
.video-player-container video {
  border-radius: 1rem;
}

.video-element {
  width: 100%;
  max-width: 100%;
}

.video-controls {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.control-button {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.control-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.youtube-vimeo-element {
  border-radius: 1rem;
  border: 1px solid var(--primary-tint-color);
  aspect-ratio: 16/9;
}
