.checkin-action-settings {
  margin-right: 1.75rem;
  color: var(--primary-action-color);
}

.checkin-action-settings:hover {
  cursor: pointer;
  opacity: 0.6;
}

.action-checkin-description {
  margin-left: 1rem;
}

.action-connect-description {
  margin-left: 0.5rem;
}

.action-connection-item {
  margin-right: 0.5rem;
}

.video-and-action-wrapper {
  border: 1px solid var(--secondary-tint-color);
  padding: 0.5rem;
  border-radius: 0.75rem;
}

.actions-container .event-item {
  border-radius: 0.75rem;
}

.actions-container .MuiAccordionDetails-root {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
