.response-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 15px;
  padding: 0 1rem;
  background-color: var(--container-background-color);
}

.daily-insight-header {
  text-align: center;
  padding: 0.5rem;
}

.participation-title {
  font-weight: bold;
  font-size: 24px;
}

.percentage-container {
  font-weight: bold;
  font-size: 32px;
}

.response-container .MuiAccordion-root {
  width: 100% !important;
  background-color: var(--primary-background-color) !important;
  margin-bottom: 1rem !important;
}

.description {
  font-weight: bold;
}

.response {
  font-style: italic;
}

.reports-overview-header {
  display: flex;
  width: 100%;
  margin: auto;
}

.health-display-container {
  margin-left: 0.5rem;
}

.participation-display-container {
  margin-right: 0.5rem;
}

.response-container-item .MuiAccordion-root {
  margin-top: 0 !important;
}
