.edit-error {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
}

.save-message {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #4c9a2a;
  background: #acdf87;
  border-radius: 5px;
  border: 1px solid #4c9a2a;
  padding: 0.5rem;
  font-weight: bold;
}

.profile-settings-avatar {
  width: 10rem;
  height: 10rem;
  font-size: 3rem;
  margin: 1rem 0 1.5rem 0;
  border-radius: 50%;
  background-color: var(--avatar-background-color);
  text-align: center;
  margin-right: 0.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  overflow: hidden;
}

.profile-settings-avatar.with-image {
  background-color: transparent;
  border: 1px solid var(--secondary-tint-color);
}

.datePicker {
  z-index: 10;
  text-align: left;
}

input:disabled {
  background-color: var(--primary-tint-color);
}

.footer-button {
  margin-top: 3.5rem;
  width: fit-content;
}

.profile-settings-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: auto;
}

.profile-settings-header {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
  width: 100%;
  padding-left: 0.75rem;
}

.profile-settings-nav {
  display: flex;
  flex-direction: column;
  font-size: 1.35rem;
  align-items: center;
  height: 100%;
  width: 250px;
  z-index: 10;
}

.profile-settings-nav-item {
  padding: 0.5rem 0.75rem;
  display: block;
  text-align: left;
  width: 100%;
  color: var(--primary-font-color);
  background-color: transparent;
  transition: background-color 0.12s ease-in-out, opacity 0.12s ease-in-out;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  opacity: 0.35;
  font-size: 1.25rem;
  font-weight: bold;
}

.profile-settings-nav-item-selected {
  padding: 0.5rem 0.75rem;
  display: block;
  text-align: left;
  width: 100%;
  color: var(--primary-font-color);
  background-color: var(--primary-tint-color);
  transition: background-color 0.12s ease-in-out, opacity 0.12s ease-in-out;
  border-radius: 0.75rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  opacity: 1;
  font-size: 1.25rem;
  font-weight: bold;
}

.profile-settings-nav-item:hover {
  opacity: 1;
  cursor: pointer;
}

.profile-setting-container {
  width: 100%;
  border-radius: 15px;
  border: 1px solid var(--container-background-color);
  background-color: var(--container-background-color);
  margin: auto;
  margin-bottom: 1rem;
}

@media (max-width: 750px) {
  .profile-settings-nav {
    height: auto;
    flex-direction: row;
    border-top: 1px solid var(--primary-tint-color);
    border-bottom: 1px solid var(--primary-tint-color);
    padding: 0.25rem 1rem 0 1rem;
    width: 100%;
    top: 4rem;
    position: fixed;
    margin: 0 -1rem;
    background-color: var(--primary-background-color);
  }

  .profile-settings-header {
    display: none;
  }

  .profile-settings-nav-item {
    text-align: center;
    flex-direction: column;
    font-size: 0.85rem;
    padding: 0.25rem;
    transition: opacity 0.12s ease-in-out;
  }

  .profile-settings-nav-item-selected {
    background-color: transparent;
    text-align: center;
    flex-direction: column;
    font-size: 0.85rem;
    padding: 0.25rem;
    transition: opacity 0.12s ease-in-out;
  }
}

@media (max-width: 750px) {
  .date-picker,
  .phone-field {
    width: 100%;
  }
}

.profile-card-wrapper {
  max-width: 700px;
  margin: 0 auto;
}

.profile-card {
  background-color: var(--container-background-color);
  border-radius: 0.75rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2rem;
  position: relative;
}

.profile-card .profile-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 2rem;
}

.profile-card .profile-info h2 {
  margin: 0;
}

.profile-card .profile-info p {
  margin: 0.5rem 0 0;
}

.profile-card .profile-actions {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  display: flex;
  gap: 0.5rem;
}
