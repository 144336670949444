.tableLink {
  display: block;
  cursor: pointer;
  color: var(--primary-action-color);
  text-decoration: none;
}

.weekly-achievements-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 15px;
  padding: 1rem 1rem;
  margin-top: 2rem;
  background-color: var(--container-background-color);
  padding-top: 0;
  height: 50vh;
  overflow: auto;
}

.weekly-achievements-container td {
  padding: 1rem 0;
}

table.people tr th:last-child,
table.people tr td:last-child {
  width: auto;
  padding: 0.5rem 0;
}

.weekly-achievements-container h4 {
  margin-top: 0;
  font-weight: normal;
  opacity: 0.75;
}