.program-dropdown-container {
  display: block;
  margin-top: 1rem;
}

.location-header {
  display: flex;
  justify-content: space-between;
}

.description {
  font-size: 1.1rem;
  margin: 1rem 0;
  margin-bottom: 1.5rem;
}

.checkbox {
  margin-top: -0.8rem;
}

.checkbox-container {
  display: flex;
}

.checkbox .Mui-checked {
  color: var(--primary-action-color) !important;
}

.name-input {
  margin: 1rem 0;
}

.program-dropdown-description {
  opacity: 0.5;
  margin-top: 0.25rem;
  font-size: 0.9rem;
}

.addons-toggle-container {
  background: var(--input-background-color);
  border: 1px solid var(--secondary-tint-color);
  border-radius: 0.75rem;
  margin-top: 1rem;
}

.component-toggle-container {
  border-bottom: 1px solid var(--secondary-tint-color);
  padding: 0.375rem 0.75rem;
  padding-right: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
}

.component-toggle-container:last-child {
  border-bottom: none;
}

.Mui-checked {
  color: var(--primary-action-color) !important;
}

.Mui-checked + .MuiSwitch-track {
  background-color: var(--primary-action-color) !important;
}

.Mui-disabled {
  opacity: 0.6;
}

.MuiSwitch-track {
  background-color: var(--secondary-tint-color) !important;
}

.Mui-checked .MuiSwitch-thumb {
  background-color: var(--primary-action-color) !important;
}

.MuiSwitch-thumb {
  background-color: var(--secondary-avatar-background-color) !important;
}

.addEdit-space-error {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
}
