.person-modal-close-button {
  cursor: pointer;
  display: flex;
  align-self: top;
}

.person-settings-modal-sub-content{
  overflow-y: auto;
}

.modal-username{
  font-weight: bold;
}