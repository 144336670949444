.app {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
}

.app-content {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  max-height: 100%;
  height: 100%;
  padding: 1rem;
  padding-top: 0;
  width: 100%;
  padding-bottom: 6rem;
}

input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.75rem;
  border: none;
  font-size: 1rem;
  margin: 0.5rem 0;
  border: 1px solid var(--secondary-tint-color);
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
}

input:focus {
  outline: 1px solid var(--primary-action-color);
  border: 1px solid var(--primary-action-color);
}

textarea {
  border: 1px solid var(--secondary-tint-color);
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
  width: 100%;
  height: 10rem;
  border-radius: 0.75rem;
  padding: 0.75rem;
  font-size: 1rem;
  margin: 0.5rem 0;
  resize: vertical;
}

.button {
  display: flex;
  padding: 0.5rem 1.5rem;
  border-radius: 0.75rem;
  background-color: var(--primary-action-color);
  border: 1px solid var(--primary-action-color);
  font-weight: bold;
  text-decoration: none;
  user-select: none;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  transition: 0.2s opacity ease;
  cursor: pointer;
  min-height: fit-content;
}

.button.danger {
  background-color: #d23e3d;
  border-color: #d23e3d;
}

.button.ghost {
  background-color: transparent;
  border-color: transparent;
  color: var(--primary-font-color);
}

.button.secondary {
  background-color: var(--secondary-tint-color);
  border-color: var(--secondary-tint-color);
  color: var(--primary-font-color);
}

.button.sm {
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
}

.button.transparent {
  background-color: transparent;
  border-color: transparent;
  color: var(--primary-font-color);
}

.button.transparent.danger {
  color: #d23e3d;
}

.button.icon {
  width: 2.5rem;
  padding: 0 !important;
}

.button:hover {
  opacity: 0.6;
}

.button .material-icons {
  margin-left: 0.5rem;
  transform: rotate(45deg);
}

.manage-space-modal .modal {
  height: 500px;
}

@media (max-width: 750px) {
  .manage-space-modal .modal {
    height: 100%;
  }
}

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1000000;
  background-color: hsl(0deg 0% 0% / 75%);
  backdrop-filter: blur(5px);
}

.modal-close {
  display: block;
  position: fixed;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal {
  position: relative;
  width: 100%;
  border-radius: 15px;
  background-color: var(--container-background-color);
  color: var(--primary-font-color);
  margin: 1rem;
  max-height: 90vh;
  overflow: auto;
}

.modal-large-size {
  max-width: 700px;
}

.modal-normal-size {
  max-width: 500px;
}

.modal.without-overflow {
  overflow: visible;
}

.modal-content {
  padding: 0 1.5rem;
}

.modal-slide-out {
  position: fixed;
  right: 0;
  width: 100%;
  max-width: 400px;
  border-radius: 0;
  max-height: 100vh;
  height: 100vh;
  margin: 0;
}

.modal-slide-out .modal-content {
  padding: 0;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 1.5rem;
  padding-bottom: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--container-background-color);
}

.modal-title {
  width: 100%;
  text-align: left;
  margin-right: 2rem;
}

.modal-footer {
  padding: 1.5rem;
  padding-top: 0.5rem;
  position: sticky;
  bottom: -1px;
  background-color: var(--container-background-color);
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  backdrop-filter: blur(10px);
}

.modal-footer .button:only-child {
  margin-left: auto;
}

.modal-close-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.modal-close-button:focus {
  outline: none;
}

.error-message-outer {
  display: flex;
  background-color: var(--primary-font-color);
  color: var(--primary-background-color);
  position: fixed;
  right: 5%;
  bottom: 5%;
  width: 450px;
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  z-index: 2000;
}

.error-button {
  padding: 0.5rem;
  border: none;
  margin: 5px;
}

.error-close-button {
  margin: 0 5px 0 5px;
}

@media (max-width: 750px) {
  .app {
    flex-flow: column-reverse;
    overflow: unset;
    height: auto;
  }

  .home-page .app-content {
    padding-top: 2.4rem;
  }

  .app-content {
    padding-top: 0;
    height: auto;
    overflow: unset;
  }

  .modal {
    position: fixed;
    width: 100%;
    height: 100dvh;
    max-height: 100dvh;
    margin: 0;
    top: 0;
    border-radius: 0;
    max-width: 100%;
    max-height: -webkit-fill-available;
  }

  .modal-header {
    padding: 1rem;
    width: 100%;
  }

  .modal-close-button {
    top: 1rem;
    right: 1rem;
  }

  .modal-content {
    padding: 0 1rem;
    padding-bottom: 5rem;
  }

  .modal-footer {
    position: fixed;
    width: 100%;
    left: 1.5rem;
    bottom: 0;
    padding: 1rem;
  }

  .modal-footer .button {
    width: 100%;
  }

  .modal-footer .button:last-child {
    margin-left: 1rem;
  }

  .modal-footer .button:only-child {
    margin-left: 0;
  }

  .crisp-client #crisp-chatbox a {
    bottom: 5rem !important;
  }
}
