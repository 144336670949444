.connection-user-item svg {
  color: var(--primary-font-color);
}

.connection-user-item {
  margin-bottom: .75rem;
  background-color: var(--primary-background-color);
  padding: 0.5rem;
  border-radius: 15px;
  min-height: 65px !important;
}

.review-connection-user-item {
  padding: 0.25rem 0;
  border-radius: 15px;
}

.MuiAccordion-root.checkin {
  max-width: 100% !important;
  width: 100% !important;
}

.MuiAccordion-root.review-checkin {
  max-width: 100% !important;
  width: 100% !important;
  background-color: var(--primary-background-color) !important;
}

.connection-user-item label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.connection-user-name-container {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  width: 100%;
}

.connection-user-item-avatar {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  background-color: var(--avatar-background-color);
  font-weight: bold;
  text-align: center;
  margin-right: 0.5rem;
  font-size: 1rem;
  color: var(--primary-font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  user-select: none;
}

.connection-message {
  margin: 1rem;
}

.connection-user-item  label .MuiCheckbox-root {
  margin: 0.2rem 0 0 0.25rem !important;
}

.connection-user-checkin-checkbox-container {
  display: flex;
  align-items: flex-start;
}

.checkin-button-container {
  padding: 1rem;
}

.connection-user-name-container-report {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin-top: 0.35rem;
  background-color: var(--primary-background-color);
  padding: 0.5rem;
  border-radius: 15px;
}

.report-section-title {
  font-weight: bold;
}

.review-connection-wrapper {
  display: flex;
  flex-direction: row;
}