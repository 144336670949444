.nav-title.nav-profile {
  margin-bottom: 0;
  width: 100%;
}

.organization-menu-container {
  background-color: var(--primary-background-color);
  padding: 0;
  right: 0;
  border-radius: 0.75rem;
  box-shadow: 0px 5px 12px var(--primary-background-color);
  top: 0.25rem;
  border: 1px solid var(--secondary-tint-color);
  width: 250px;
}

.organization-menu-button {
  display: flex;
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  font-weight: bold;
  text-decoration: none;
  user-select: none;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  color: var(--primary-font-color);
  transition: 0.2s opacity ease;
  cursor: pointer;
  width: 100%;
}

.organization-menu-button:hover {
  opacity: 0.6;
}

.public .profile-dropdown {
  position: absolute;
  top: 3.5rem;
  right: 1rem;
  z-index: 1;
}

.public .nav-avatar {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
}

.public .nav-title.nav-profile {
  min-height: 2.5rem;
}

.profile-dropdown {
  position: absolute;
  bottom: 4.5rem;
  z-index: 1;
}

.profile-dropdown .MuiPaper-root {
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
  border: 1px solid var(--primary-tint-color);
  border-radius: 0.75rem;
  max-height: calc(100dvh - 6rem);
  overflow-y: auto;
}

.profile-dropdown .MuiSvgIcon-root {
  color: var(--primary-action-color);
}

.profile-dropdown hr {
  border-color: var(--primary-tint-color);
  margin: 0.7rem 0;
}

.profile-dropdown .MuiMenuItem-root {
  border-radius: 8px;
  margin: 0 0.5rem;
  padding: 0 0.5rem;
}

.profile-dropdown .MuiMenuItem-root:hover {
  background-color: var(--primary-tint-color);
}

.studio-access .MuiListItemText-primary {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.studio-access .MuiListItemText-primary svg {
  color: var(--primary-font-color);
  margin-left: 0.25rem;
}

@media (max-width: 750px) {
  .nav-bar .profile-dropdown {
    top: 2.7rem;
    left: 0.5rem;
    z-index: 10;
  }
}

.nav-profile {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #d23e3d;
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 20px;
  min-width: 1.5rem;
}

.avatar-container {
  position: relative;
}

.mobile-notification-dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background-color: #ff0000; /* or your preferred notification color */
  border-radius: 50%;
}
