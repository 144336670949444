.community-join-button {
  font-size: 1rem;
  padding: 1rem;
}

.invite-container {
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid var(--primary-tint-color);
  background-color: var(--container-background-color);
  padding: 2rem;
  border-radius: 1rem;
  width: 100%;
  min-height: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
