.subnav-header {
  padding: 1rem;
  width: calc(100% + 2rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--subnav-background-color);
  align-items: center;
  min-height: 4.5rem;
  margin-left: -1rem;
}

.subnav-title-container {
  display: flex;
  width: 100%;
}

.subnav-title-container .back-button {
  color: var(--primary-font-color);
  margin-right: 0.5rem;
  margin-left: -0.25rem;
  margin-top: 0.25rem;
  cursor: pointer;
}

.subnav-title-container .back-button svg {
  width: 1rem;
}

.title-container {
  display: flex;
  justify-content: center;
  user-select: none;
  flex-direction: column;
  min-width: 0;
}

.title-container h1 {
  font-size: 1.15rem;
}

.title-container.with-subtitle h1 {
  font-size: 1rem;
}

.title-container.with-subtitle .subtitle {
  margin: 0;
  opacity: 0.5;
  text-align: left;
  font-size: 0.9rem;
}

.subnav-logo-container {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.subnav-program-container {
  display: flex;
}

.logo {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #53565c;
  border-radius: 6px;
  margin-right: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.35rem;
  background-color: #d23e3e;
}

.subnav-program-name {
  font-size: 1rem;
}

.subnav-actions {
  display: flex;
  min-width: fit-content;
}

.subnav-actions .button:last-child {
  margin-left: 0.5rem;
}

.page-title {
  font-size: 1.15rem;
  font-weight: 600;
  margin: 0.25rem 0 0 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

@media (max-width: 750px) {
  .subnav-header {
    padding: 0;
    width: 100%;
    margin: auto;
    min-height: 3.5rem;
  }

  .subnav-title-container .back-button svg {
    width: 1.5rem;
  }
}
