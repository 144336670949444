.edit-connect-dropdown-container {
  position: relative;
}

.edit-connect-dropdown {
  position: absolute;
  z-index: 100;
  right: 0.5rem;
}

.edit-connect-dropdown .MuiPaper-root {
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
  border: 1px solid var(--primary-tint-color);
  border-radius: 0.75rem;
}

.edit-connect-dropdown .MuiSvgIcon-root {
  color: var(--primary-action-color);
}

.edit-connect-dropdown .MuiMenuItem-root {
  border-radius: 8px;
  margin: 0 0.5rem;
  padding: 0 0.5rem;
}

.edit-connect-dropdown .MuiMenuItem-root:hover {
  background-color: var(--primary-tint-color);
}
