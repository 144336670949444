.space-card-container {
  border-radius: 15px;
  color: var(--primary-font-color);
  display: flex;
  flex-direction: column-reverse;
  padding: 1.5rem;
  width: calc((700px * 0.5) - 0.5rem);
  cursor: pointer;
  min-height: 12rem;
  background-color: var(--container-background-color);
  user-select: none;
  transition: outline 0.1s ease-in-out;
  outline: 0px solid var(--primary-action-color);
  overflow: hidden;
}

.space-card-container:hover {
  outline: 3px solid var(--primary-action-color);
}

.spaces {
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 1rem;
}

.spaces-search {
  max-width: 700px;
  width: 100%;
  margin: auto;
}

.spaces-search input {
  margin-top: 0;
}

.other-spaces {
  max-width: 700px;
  margin: auto;
  width: 100%;
}

.no-hover:hover {
  cursor: default !important;
  outline: 0px solid var(--primary-action-color);
}

.spaces .no-items-found {
  margin-left: 1rem;
}

.other-spaces .space-card-container {
  min-height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  width: 100%;
}

.other-spaces .avatar-display-container {
  top: 1.2rem;
  right: 2.35rem;
}

.other-spaces h4 {
  margin-bottom: 0;
}

.other-spaces .upcoming-connection-container {
  display: none;
}

.other-spaces .overlap {
  position: absolute;
  transform: translate(-3%, -75%);
  min-width: 2.75rem;
  min-height: 2.75rem;
}

.other-spaces .overlap:nth-child(1) {
  z-index: 1;
}

.other-spaces .overlap:nth-child(2) {
  z-index: 2;
  left: -2rem;
}

.other-spaces .overlap:nth-child(3) {
  z-index: 3;
  left: -4rem;
}

.other-spaces .overlap:nth-child(4) {
  z-index: 4;
  left: -6rem;
}

.other-spaces .overlap:nth-child(5) {
  z-index: 5;
  left: -8rem;
}

.other-spaces .overlap:nth-child(6) {
  z-index: 6;
  left: -10rem;
}

.other-spaces .overlap:nth-child(7) {
  z-index: 6;
  left: -12rem;
}

@media (max-width: 1010px) {
  .unsearched-space-card-container {
    width: 100%;
  }

  .space-card-container {
    max-width: 100%;
    width: 100%;
  }
}
