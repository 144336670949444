.space-subnav-headers {
  display: flex;
  margin: -2rem -1.5rem 3rem -1.5rem;
  padding: 0 0.7rem;
  padding-top: 0.5rem;
  border-bottom: 1px solid var(--secondary-tint-color);
  z-index: 10;
  position: sticky;
  top: 55px;
  background-color: var(--container-background-color);
  user-select: none;
}
