.post-feed {
  color: var(--primary-font-color);
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.space-feed {
  margin-top: 2rem;
}

.post-feed .event-item {
  background-color: var(--tertiary-tint-color);
  margin-top: 0.25rem;
}

.post-feed_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.post-feed_header-left {
  display: flex;
  align-items: center;
}

.post-feed_header-avatar {
  background-color: #374151;
  color: white;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.post-feed_online-indicator {
  width: 12px;
  height: 12px;
  background-color: #4ade80;
  border-radius: 50%;
}

.post-feed_input {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.post-feed_input-avatar {
  background-color: #4ade80;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.post-feed_input-text {
  color: #9ca3af;
}

.post-feed_post-avatar {
  background-color: #60a5fa;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  flex-shrink: 0;
}

.post-feed_post-time {
  color: #9ca3af;
  margin-left: 8px;
}

.post-feed_post-content {
  margin-bottom: 0.5rem;
}

.post-feed_input-container {
  border-radius: 0.75rem;
  border: 1px solid var(--primary-tint-color);
  background-color: var(--container-background-color);
}

.post-feed_input-field {
  background-color: var(--container-background-color) !important;
  color: var(--primary-font-color) !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  min-height: 1rem;
  max-height: 200px;
  overflow-y: auto;
}

.post-feed_input-box .MuiInputBase-input {
  background-color: var(--container-background-color) !important;
  color: var(--primary-font-color) !important;
  position: relative;
  border-radius: 0;
  font-size: 1.1rem;
  padding-bottom: 1rem;
}

.post-feed_input-box {
  border: 1px solid transparent;
  border-radius: 0.75rem;
  overflow: hidden;
  position: relative;
}

.post-feed_input-box.is-focused {
  outline: 2px solid var(--primary-action-color);
  outline-offset: -1px;
}

.post-feed_input-box-inner {
  background-color: var(--container-background-color);
  padding: 0.75rem 0.8rem;
  padding-bottom: 0;
}

.post-feed_input-field .MuiInputBase-root,
.post-feed_input-field .MuiInputBase-root:focus-within {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.post-feed-input-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: end;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.post-feed-input-actions .MuiInputBase-root {
  border-radius: 0.75rem !important;
  color: var(--primary-font-color) !important;
  border: none !important;
  opacity: 0.7;
}

.post-feed-input-actions .MuiSelect-select {
  padding-top: 0;
  padding-bottom: 0;
}

.post-feed-input-actions .MuiSelect-select .MuiBox-root {
  font-size: 1rem;
}

.post-feed-input-actions .MuiSelect-select svg {
  height: 1rem;
  width: 1rem;
}

.post-feed-input-actions svg {
  color: var(--primary-font-color) !important;
}

.post-feed-input-actions fieldset {
  border: none !important;
}

.MuiPopover-root {
  z-index: 1000001 !important;
}

.refresh-view {
  user-select: auto !important;
}
