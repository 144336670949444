.error-page {
  display: flex;
  height: 100%;
  padding: 2rem;
  min-height: 50vh;
}

.error-page-container {
  color: var(--primary-font-color);
  max-width: 500px;
  margin: auto;
  align-self: center;
}

.error-page-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.error-page-header-text {
  font-size: 1.5rem;
  opacity: 0.5;
  margin-bottom: 2rem;
}