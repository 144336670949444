.role-options {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background-color);
  padding: 0;
  border-radius: 0.75rem;
  box-shadow: 0px 5px 12px var(--primary-background-color);
  border: 1px solid var(--secondary-tint-color);
  min-width: 200px;
  position: absolute;
  right: 0;
  z-index: 1;
}

.role-container {
  position: relative;
}

.role-container select:hover {
  cursor: pointer;
}

.member-option {
  margin: 2px 0 2px 0;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  border-radius: 0.75rem;
  background-color: var(--primary-action-color);
  border: 1px solid var(--primary-action-color);
  font-weight: bold;
  text-decoration: none;
  user-select: none;
  font-size: 1rem;
  align-items: center;
  color: #ffffff;
  transition: 0.2s opacity ease;
  cursor: pointer;
}

.space-checkbox {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
}

.space-checkbox-spacer {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.hover-container {
  position: relative;
}

.hover-text {
  position: absolute;
  top: 2.1rem;
  right: 0.2rem;
  padding: 0.5rem;
  background: var(--primary-background-color);
  color: var(--primary-font-color);
  margin-right: 2rem;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 1;
}
