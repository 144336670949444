.start-connection-modal-close-button {
  cursor: pointer;
  display: flex;
  align-self: top;
}

.start-connection-modal {
  max-width: 500px;
  background-color: var(--primary-background-color);
  width: 100%;
  border-radius: 15px;
  color: var(--primary-font-color);
  max-height: 80%;
  overflow-y: auto;
}

.start-connection-button {
  margin-top: 0;
}

.start-connection-modal-header {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: bold;
  padding: 1rem 1rem 0.5rem 1rem;
  position: sticky;
  top: 0;
  background-color: var(--primary-background-color);
}

.start-connection-modal-space-name {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.attendance-save-button {
  width: 10rem;
}

@media (max-width: 750px) {
  .start-connection-modal {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }

  .start-connection-modal-header {
    position: fixed;
    width: 100%;
  }
}
