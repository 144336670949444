.connect-share-wrapper {
  position: relative;
}

.connect-share-container {
  position: absolute;
  z-index: 1000;
  top: 2.25rem;
  right: 0rem;
  background-color: var(--primary-background-color);
  border-radius: 0.75rem;
  box-shadow: 0px 5px 12px var(--primary-background-color);
  border: 1px solid var(--secondary-tint-color);
  min-width: 350px;
  padding: 1rem;
}

.connect-location-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.copy-location {
  position: absolute;
  right: 1.8rem;
  bottom: 1.9rem;
}

.copy-location:hover {
  opacity: 0.6;
  cursor: pointer;
}

.connect-manage-button {
  margin: auto;
  max-width: 200px;
  width: 100%;
  min-height: 2rem;
}

.location {
  padding-right: 2.9rem;
  margin-bottom: 0;
  outline: none;
}
