.spaces-toggle-label {
  color: var(--primary-font-color);
  font-weight: normal;
  font-size: 1.15rem;
}

.space-toggle-container {
  padding: 0;
  margin-bottom: 1rem;
}

.admin-spaces-wrapper {
  padding-top: 1rem;
  margin: auto;
}

.card-content-container {
  display: flex;
  justify-content: space-between;
}

.organization-space-settings-container {
  display: flex;
  align-items: center;
}

.edit-connection-toggle {
  margin-bottom: 1rem;
  padding: 0;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}
