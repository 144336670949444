.media-select .MuiInputBase-root {
  width: 100% !important;
  background-color: var(--input-background-color) !important;
  border-radius: 10px !important;
  color: var(--primary-font-color) !important;
  margin: 0.5rem 0 1rem 0 !important;
  max-height: 45px !important;
  border: 1px solid var(--secondary-tint-color);
}

.MuiPopover-root {
  z-index: 1000001 !important;
}

.space-select .MuiSvgIcon-root {
  color: var(--primary-font-color) !important;
}

.MuiPopover-root .MuiCheckbox-root {
  background-color: var(--input-background-color) !important;
  color: var(--primary-font-color) !important;
  margin-right: 0.1rem !important;
}

.MuiList-root {
  background-color: var(--input-background-color) !important;
  color: var(--primary-font-color) !important;
  border-radius: 10px !important;
}

.MuiMenuItem-root {
  max-height: 45px !important;
}

.MuiPaper-root {
  border-radius: 10px !important;
}