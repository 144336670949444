.organization-role-daily-checkin-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-size: 1rem;
  font-weight: bold;
}

.email {
  opacity: 0.5;
}

.content-display {
  margin-top: 1rem;
}

.daily-checkin-user-item {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}