.comments-display {
  color: var(--primary-font-color);
  padding: 0 1rem;
  padding-left: 1.5rem;
  padding-right: 0;
  margin: 0 auto;
  margin-top: 1rem;
  border-left: 1px solid var(--secondary-tint-color);
  position: relative;
}

.comments-title {
  margin-bottom: 16px;
}

.comment-body {
  flex-grow: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.comment-controls {
  opacity: 0.25;
  color: var(--primary-font-color) !important;
}

.comments-list {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.comments-display textarea {
  border-radius: 0 !important;
}

.comment-controls:hover {
  cursor: pointer;
  opacity: 0.8;
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.comment-author {
  font-weight: 700 !important;
  font-size: 15px !important;
  color: var(--primary-font-color) !important;
}

.comment-timestamp {
  color: var(--primary-font-color) !important;
  opacity: 0.7;
  font-size: 14px !important;
  margin-left: 8px !important;
}

.comment-actions {
  display: flex;
  gap: 16px;
  margin-top: 0.5rem;
  margin-left: -0.35rem;
  user-select: none;
}

.comment-action-button {
  color: var(--primary-action-color) !important;
}

.comment-action-button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.comment-replies-toggle {
  color: var(--primary-action-color) !important;
}

.comment-replies {
  border-left: 1px solid var(--secondary-tint-color);
}

.comment-replies-list {
  margin-top: 0.5rem;
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.new-comment-form {
  margin-bottom: 16px;
}

.new-comment-input {
  margin-bottom: 8px;
}

.new-comment-input textarea {
  min-height: 2.15rem !important;
  border: none !important;
  padding-right: 7rem;
}

.new-comment-input .MuiOutlinedInput-root {
  background-color: var(--primary-background-color) !important;
  color: var(--primary-font-color);
  border: 1px solid var(--secondary-tint-color);
  border-radius: 0.75rem !important;
}
.new-comment-input
  .MuiInputBase-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--primary-action-color) !important;
}

.comment-avatar {
  height: 1.6rem;
  width: 1.6rem;
  min-width: 1.6rem;
  min-height: 1.6rem;
  border-radius: 50%;
  background-color: var(--avatar-background-color);
  text-align: center;
  margin-right: 0.5rem;
  font-size: 0.7rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
}

.comment-avatar:hover {
  cursor: pointer;
  opacity: 0.6;
}

.submit-comment-button {
  margin-top: 8px !important;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.submit-comment-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.reply-form {
  margin-top: 8px;
}

.reply-input {
  margin-bottom: 8px;
}

.reply-input .MuiOutlinedInput-root {
  background-color: var(--container-background-color);
  color: var(--primary-font-color);
}

.edit-input .MuiOutlinedInput-root {
  background-color: var(--container-background-color);
  color: var(--primary-font-color);
}

.comment-edit-form .MuiInputBase-root {
  color: var(--primary-font-color) !important;
  background-color: var(--primary-background-color) !important;
  border-radius: 0.5rem !important;
  border: 1px solid var(--secondary-tint-color);
}

.comment-edit-form
  .MuiInputBase-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--primary-action-color) !important;
}

.comment-edit-form-button {
  background-color: var(--primary-action-color) !important;
  color: var(--primary-font-color) !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.comment-edit-form-button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.comment-edit-form-cancel-button {
  color: var(--primary-action-color) !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.comment-edit-form-cancel-button:hover {
  cursor: pointer;
  opacity: 0.6;
}
