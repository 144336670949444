.addon-container.program .program-content {
  margin: auto;
  max-width: 700px;
}

.addon-container.program .progress-bar {
  background-color: var(--secondary-tint-color);
}

.addon-container.program .exercise-list-item {
  border: 1px solid var(--secondary-tint-color);
}

.addon-container.program .exercise-list-container {
  max-width: 700px;
}

.addon-container.program select {
  margin-top: 0;
  padding: 0.5rem;
  background-color: var(--input-background-color);
}

.course-toggle:hover {
  cursor: pointer;
  opacity: 0.6;
}

.course-name {
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
}