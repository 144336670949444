.MuiAutocomplete-popper {
  z-index: 10000000 !important;
  background-color: var(--container-background-color) !important;
  border-radius: 0.75rem !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox {
  background-color: var(--container-background-color) !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option {
  color: var(--primary-font-color) !important;
  background-color: var(--container-background-color) !important;
  margin: 0.25rem !important;
  border-radius: 0.75rem !important;
}

.MuiAutocomplete-popper
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option.Mui-focused {
  background-color: var(--primary-background-color) !important;
}

.tag-container .MuiInputBase-input {
  color: var(--primary-font-color);
}

.tag-container .MuiInputBase-input::placeholder {
  color: var(--primary-font-color);
  opacity: 0.6;
}

.tag-container .MuiAutocomplete-inputRoot {
  color: var(--primary-font-color);
}

.tag-container .MuiInputLabel-root {
  color: var(--primary-font-color);
}

.tag-container .MuiChip-root {
  background-color: var(--container-background-color) !important;
  color: var(--primary-font-color);
}

.tag-container .MuiChip-deleteIcon {
  color: var(--primary-font-color) !important;
}

.tag-container .MuiChip-deleteIcon:hover {
  opacity: 0.6 !important;
}

.tag-container .MuiInput-root:after {
  display: none !important;
}

.tag-container .MuiInput-root:before {
  display: none !important;
}

.tag-container .MuiButtonBase-root {
  color: var(--primary-font-color) !important;
}

.tag-container .MuiButtonBase-root:hover {
  opacity: 0.6 !important;
}

.tag-container .MuiAutocomplete-root {
  background-color: var(--primary-background-color);
  border-radius: 0.75rem;
  padding: 10px;
}
