.attendance-table-link {
  color: var(--primary-action-color);
  text-decoration: none;
  display: flex;
  margin: 0;
  margin-right: 0.5rem;
  align-items: center;
}

.attendance-table-link:hover {
  cursor: pointer;
}

.detail-table-link {
  margin-right: 2rem;
}

.attendance-table-link .MuiSvgIcon-root {
  margin-right: 0.25rem;
}

.connect-startTime-display-container {
  margin: 0;
}

.connect-attendance-display-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.weekly-health-container tr td {
  padding: 0.25rem 0;
  height: 49px;
}

.weekly-health-container tr th {
  padding: 0.75rem 0;
}

.connects-table-container.with-attendance:hover {
  cursor: pointer;
}

.connectionTrue {
  background-color: #33b75e;
  color: black;
  border-radius: 5px;
  text-align: center;
  max-width: fit-content;
  padding: 0.25rem;
  font-weight: bold;
  margin-left: 0;
}

.connectionFalse {
  background-color: #f0bc3a;
  color: black;
  border-radius: 5px;
  text-align: center;
  max-width: fit-content;
  padding: 0.25rem;
  font-weight: bold;
  margin-left: 0;
}

.weekly-health-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 15px;
  padding: 0 1rem;
  background-color: var(--container-background-color);
  height: 50vh;
  overflow: auto;
}

.weekly-health-container h2 {
  margin-bottom: 0.5rem;
}

.weekly-health-container h4 {
  margin-top: 0;
  font-weight: normal;
  opacity: 0.75;
}

.metrics-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.metric-container {
  border: 1px solid var(--secondary-tint-color);
  padding: 1rem;
  border-radius: 0.75rem;
}

.metric-container .metric-data {
  font-size: 2rem;
}

.metric-container .metric-label {
  font-size: 1rem;
  opacity: 0.5;
}

tr td {
  padding: 0.5rem 0;
}

.download-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.download-buttons a {
  text-decoration: none;
}

.weekly-report-meta {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1.25rem;
}

table.people tr th:last-child,
table.people tr td:last-child {
  width: auto;
  padding: 0.5rem 0;
}

.connects-table-container .MuiAccordion-root {
  max-width: 100% !important;
  border-radius: 15px !important;
  background-color: var(--primary-background-color) !important;
}

.connects-table-container .MuiPaper-root {
  margin: 0 !important;
}

.connects-table-container .MuiAccordionSummary-root .MuiTypography-root {
  width: 100%;
}

@media (max-width: 950px) {
  .attendance-table-link {
    margin-left: 1rem;
  }

  .weekly-report-meta {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  .csv-download {
    display: none !important;
  }
}
