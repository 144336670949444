.media-toggle {
  position: absolute;
  top: 50%;
  width: 35px;
  height: 35px;
  transform: translateY(-50%);
  background-color: rgba(0,0,0,0.5);
  border-radius: 50%;
  padding: 5px;
}

.media-toggle-right {
  right: 10px;
}

.media-toggle-left {
  left: 10px;
}

.media-toggle:hover {
  cursor: pointer;
  opacity: 0.6;
}
