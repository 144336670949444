.live-icon {
  font-weight: bold;
  background-color: rgb(208, 72, 66);
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
  width: fit-content;
  margin: auto;
}

.connect-type-selection-container {
  display: flex;
  justify-content: space-evenly;
}

.connect-type-selection {
  border: 4px solid var(--secondary-tint-color);
  max-width: 250px;
  width: 100%;
  padding: 1rem;
  border-radius: 0.75rem;
  margin: 0.5rem;
}

.watch-icon {
  text-align: center;
}

.connect-type-selection-selected {
  border: 4px solid var(--primary-action-color);
  max-width: 250px;
  width: 100%;
  padding: 1rem;
  border-radius: 0.75rem;
  margin: 0.5rem;
}

.selection-title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0.5rem;
}

.connect-type-selection:hover {
  opacity: 0.6;
  cursor: pointer;
}

.connect-metaData {
  margin: 0.75rem 0;
}

.footer {
  margin: 0.5rem;
}

.create-connection-error {
  font-size: 0.9rem;
  text-align: center;
  margin: 0.5rem 0.5rem 1rem 0.5rem;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
}

.MuiAutocomplete-popper {
  z-index: 10000000 !important;
  background-color: var(--container-background-color) !important;
  border-radius: 0.75rem !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox {
  background-color: var(--container-background-color) !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option {
  color: var(--primary-font-color) !important;
  background-color: var(--container-background-color) !important;
  margin: 0.25rem !important;
  border-radius: 0.75rem !important;
}

.MuiAutocomplete-popper
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option.Mui-focused {
  background-color: var(--primary-background-color) !important;
}

.connect-metaData .MuiInputBase-input {
  color: var(--primary-font-color);
}

.connect-metaData .MuiInputBase-input::placeholder {
  color: var(--primary-font-color);
  opacity: 0.6;
}

.connect-metaData .MuiAutocomplete-inputRoot {
  color: var(--primary-font-color);
}

.connect-metaData .MuiInputLabel-root {
  color: var(--primary-font-color);
}

.connect-metaData .MuiChip-root {
  background-color: var(--container-background-color) !important;
  color: var(--primary-font-color);
}

.connect-metaData .MuiChip-deleteIcon {
  color: var(--primary-font-color) !important;
}

.connect-metaData .MuiChip-deleteIcon:hover {
  opacity: 0.6 !important;
}

.connect-metaData .MuiInput-root:after {
  display: none !important;
}

.connect-metaData .MuiInput-root:before {
  display: none !important;
}

.connect-metaData .MuiButtonBase-root {
  color: var(--primary-font-color) !important;
}

.connect-metaData .MuiButtonBase-root:hover {
  opacity: 0.6 !important;
}

.connect-metaData .MuiAutocomplete-root {
  background-color: var(--primary-background-color);
  border-radius: 0.75rem;
  padding: 10px;
}

@media (max-width: 750px) {
  .connect-type-selection-container {
    margin-top: 2rem;
  }
}

.datetime-inputs {
  display: flex;
  gap: 10px;
}

.datetime-inputs input,
.datetime-inputs select {
  flex: 1;
}

.privacy-selector {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.privacy-description {
  font-size: 0.85em;
  opacity: 0.6;
  margin-top: 2px;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: var(--calendar-icon-filter);
}

@media (prefers-color-scheme: dark) {
  :root {
    --calendar-icon-filter: invert(1);
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --calendar-icon-filter: opacity(0.6);
  }
}
