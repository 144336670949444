.program-highlight-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  padding: 0.75rem;
  border: 2px solid var(--container-background-color);
  color: var(--primary-font-color);
  width: 100%;
  max-width: 400px;
  margin: auto;
  margin-top: 1rem;
  user-select: none;
}

.program-highlight-container .headline {
  opacity: 0.5;
  font-size: 0.9rem;
}

.program-highlight-container .program-details-container {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}

.program-highlight-container .program-name {
  font-size: 1.1rem;
  font-weight: bold;
}
