.login-container .title {
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: center;
}

.login-error {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
}

.login-info-wrapper {
  margin-top: 25px;
  background-color: var(--tertiary-tint-color);
  padding: 15px;
  border-radius: 0.75rem;
  font-size: 0.8rem;
}

.login-with-password-link {
  color: var(--primary-action-color);
  text-decoration: underline;
  cursor: pointer;
}

.login-with-password-link:hover {
  color: var(--primary-action-color-highlight);
}

.login-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  padding: 2rem;
  background-color: var(--container-background-color);

  color: var(--primary-font-color);
  width: 100%;
  max-width: 400px;
  margin: 1rem auto;
}

.login-container a {
  text-decoration: none;
}

.login-container-wrapper {
  display: flex;
  justify-content: center;
  min-height: calc(100dvh - 60px);
  flex-direction: column;
  padding: 1rem;
}

.login-container .button {
  margin-top: 1rem;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--primary-font-color);
  opacity: 0.5;
}

.forgot-password-wrapper {
  margin-top: 1.5rem;
}

.forgot-password-container {
  width: 50%;
  text-align: left;
}

.forgot-password-container:hover {
  color: #2196f3;
  cursor: pointer;
}

.cancel-and-return {
  font-size: 1rem;
  text-align: center;
  color: var(--primary-font-color);
  opacity: 0.5;
  margin: auto;
}

.cancel-and-return:hover {
  opacity: 0.3;
  cursor: pointer;
}

.login-action-container {
  display: flex;
  justify-content: space-between;
}

.login-wrapper {
  max-width: 400px;
  margin: 0 auto;
}

@media (max-width: 750px) {
  .login-container {
    padding: 1.5rem;
  }
}
