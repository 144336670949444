.exercise-list-container {
  max-width: 700px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
}

.progress-bar {
  width: 100%;
  min-height: 1rem;
  background-color: var(--container-background-color);
  border-radius: 0.75rem;
  overflow: hidden;
}

.progress-bar-inner {
  height: 1rem;
  background-color: #009688;
  transition: width 0.5s ease-in-out;
}

.progress-bar-text {
  font-size: 0.85rem;
  text-align: left;
}

.progress-metadata {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: center;
  margin-top: 0.5rem;
  width: 100%;
}

.show-completed-button {
  font-size: 0.85rem;
  font-weight: bold;
  color: var(--primary-action-color);
  cursor: pointer;
  user-select: none;
}

.exercise-list {
  width: 100%;
}

.exercise-container hr {
  border-color: var(--primary-tint-color);
}
