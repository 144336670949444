.activate-organization-modal .MuiAlert-root {
  border-radius: 0.75rem;
  margin-top: 1.5rem;
}

.activate-organization-menu .nav-title {
  margin: 0;
  padding: 0;
}

.payment-container {
  margin-top: 1rem;
}

.element-container {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.75rem;
  border: none;
  font-size: 1rem;
  margin: 0.5rem 0;
  border: 1px solid var(--secondary-tint-color);
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
}

.logo {
  background-color: var(--secondary-tint-color) !important;
}
