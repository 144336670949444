.secondary-nav-leader-container {
  border: 2px solid var(--secondary-nav-avatar-background-color);
  background-color: var(--avatar-background-color);
  border-radius: 25px;
  max-width: 1.75rem;
  max-height: 1.75rem;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
}

.secondary-nav-member-container {
  border: 2px solid var(--secondary-nav-avatar-background-color);
  background-color: var(--container-background-color);
  border-radius: 25px;
  max-width: 1.75rem;
  max-height: 1.75rem;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-nav-excess-container { 
  border: 2px solid var(--secondary-nav-avatar-background-color);
  background-color: var(--input-background-color);
  border-radius: 25px;
  overflow: hidden;
  max-width: 1.75rem;
  max-height: 1.75rem;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-nav-avatar-display-container{
  display: flex;
  position: relative
}

.secondary-nav-no-avatar { 
  overflow: hidden;
  display: flex;
}

.secondary-nav-no-avatar div {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 2.75rem;
  min-height: 2.75rem;
}

.secondary-nav-spaces-avatar {
  border: 2px solid var(--secondary-nav-avatar-background-color);
  overflow: hidden;
  height: 1.75rem;
  min-width: 1.75rem;
  border-radius: 50%;
  margin-top: 2px;
}

.space-member-length-container {
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0 0.5rem;
}

@media (max-width: 1000px) {
  .secondary-nav-avatar-display-container {
    display: none;
  }

  .space-member-length-container {
    border: 2px solid var(--secondary-nav-avatar-background-color);
    background-color: var(--container-background-color);
    border-radius: 25px;
    width: 1.75rem;
    height: 1.75rem;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: auto;
  }
}


