.program-progress-card-container {
  border-radius: 15px;
  padding: 1rem;
  width: 100%;
  background-color: var(--container-background-color);
  margin-bottom: 1rem;
  transition: outline 0.1s ease-in-out;
  cursor: pointer;
  outline: 0px solid var(--primary-action-color);
}

.program-card-name {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 1rem;
}

.program-progress-card-container:hover {
  outline: 3px solid var(--primary-action-color);
}

.progress-bar-display {
  width: 100%;
  min-height: 1rem;
  background-color: var(--primary-background-color);
  border-radius: 0.75rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.progress-bar-display-inner {
  height: 1rem;
  background-color: #009688;
  transition: width 0.5s ease-in-out;
}

.progress-display-wrapper {
  max-width: 700px;
  width: 100%;
}

.reset-program-button {
  max-height: 25px;
  padding: 0.5rem !important;
  margin-top: 1rem !important;
}
