.search-bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.search-bar {
  border: 1px solid var(--secondary-tint-color);
  flex: 2 1;
  padding: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0.75rem;
}

.search-text {
  border: 1px solid var(--secondary-tint-color);
  padding: 3px;
  border-radius: 5px;
  font-size: 0.9rem;
  flex: 2;
  margin-left: 0.5rem;
  height: 100%;
}

.no-space-search-content {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin: 2rem;
}

.select-all-checkbox {
  font-size: .7em;
}