.goals-container {
  max-width: 700px;
  margin: auto;
}

.goal-container {
  display: flex;
  justify-content: space-between;
  background-color: var(--container-background-color);
  margin-bottom: 1rem;
  border-radius: 0.75rem;
  padding: 0.5rem;
  align-items: center;
}

.goal-container .title {
  word-break: break-word;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.goal-container .title svg {
  margin-right: 0.5rem;
}

.goal-button {
  height: 2rem;
  padding-right: 0;
}

.goal-button:hover {
  opacity: 0.6;
}

.goal-button-container {
  display: flex;
  align-items: center;
}

@media (max-width: 750px) {
  .goals-container {
    margin-top: 0rem;
  }
}
