.new-action-button-container {
  position: absolute;
  right: 0.5rem;
  top: 0.55rem;
}

.new-action-button-container .button {
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
}

.new-action-container {
  display: flex;
  position: relative;
  align-items: center;
}

.cancel-save-action-button-container {
  position: absolute;
  right: 0.5rem;
  top: 0.55rem;
  display: flex;
}

.cancel-save-action-button-container .button {
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  margin: 0 3px;
}

.new-action-container svg {
  position: absolute;
  opacity: 0.35;
  top: 0.65rem;
  transition: opacity 0.2s ease-in-out;
}

.new-action-container svg:hover {
  opacity: 1;
  cursor: pointer;
}

.completed {
  margin-left: 0.1rem;
}

.action-input {
  padding-right: 7.5rem;
  max-height: 47px;
  margin-top: 0;
}

.action-input-pinned {
  max-height: 47px;
}

.update-action-input {
  max-height: 47px;
  padding-right: 12.5rem;
  margin-top: 0;
}

.update-action-input-pinned {
  max-height: 47px;
}

.new-action-title {
  color: var(--primary-font-color);
  font-size: 18px;
  font-weight: bold;
  margin: 1rem 1rem 0 0;
}

.edit-button-container {
  display: flex;
  flex-direction: row-reverse;
}

.edit-action-button {
  color: var(--primary-font-color);
  margin: 0.25rem;
}

.edit-action-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.action-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.6rem 0.75rem;
  border-radius: 0.75rem;
  font-size: 1rem;
  margin: 0.5rem 0;
  border: 1px solid var(--secondary-tint-color);
  color: var(--primary-font-color);
  align-items: center;
}

.description-display {
  word-break: break-word;
}

.description-display-pinned {
  word-break: break-word;
}

.action-due-container {
  margin: 0.5rem 1rem 0 0;
}

.due-by {
  text-align: center;
  background-color: var(--secondary-avatar-background-color);
  padding: 0 0.35rem;
  font-size: 0.8rem;
  border-radius: 5px;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  user-select: none;
  font-weight: bold;
}

.addon-container.tasks .empty-hero {
  margin-top: 2rem;
}

.addon-container.tasks .avatar {
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  border-radius: 50%;
  background-color: var(--avatar-background-color);
  font-weight: bold;
  text-align: center;
  font-size: 0.9rem;
  color: var(--primary-font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0.15rem 0.25rem 0 0;
}

.edit-delete-action-button-container {
  display: flex;
}

.edit-delete-action-button-container .button {
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  margin: 0 3px;
}

.action-create-assignement-dropdown {
  position: absolute;
  right: 3.75rem;
  top: -0.25rem;
  width: 10rem;
}

.action-create-dueBy-dropdown {
  position: absolute;
  right: 5.25rem;
  top: 0.73rem;
  width: 10rem;
  z-index: 1000;
}

.action-edit-assignement-dropdown {
  position: absolute;
  right: 8.75rem;
  top: -0.25rem;
  width: 10rem;
  z-index: 10;
}

.action-edit-dueBy-dropdown {
  position: absolute;
  right: 10.25rem;
  top: 0.75rem;
  width: 10rem;
  z-index: 10;
}

.react-datepicker-popper {
  position: fixed !important;
}

.down-arrow-icon {
  position: absolute;
  top: -3px;
  right: 23px;
}

.custom-date-picker-input {
  background-color: var(--primary-background-color);
  height: 2.7rem;
  border-radius: 0.75rem;
  border: 1px solid var(--secondary-tint-color);
  top: 10px;
}

.custom-date-picker-input:hover {
  cursor: pointer;
}

.custom-date-picker-input-value {
  padding-top: 10px;
  padding-left: 10px;
}
