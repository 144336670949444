.member-card-container {
  border-radius: 15px;
  color: var(--primary-font-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  padding: 0.5rem;
  width: 100%;
  cursor: pointer;
  background-color: var(--container-background-color);
}

.space-name-input {
  border: 1px solid var(--secondary-tint-color);
}

.search-bar {
  border: 1px solid var(--secondary-tint-color);
  flex: 2 1;
  padding: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0.75rem;
}
