.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 1rem;
  max-width: 700px;
  margin: auto;
  background-color: var(--container-background-color);
}

.profile-container .button {
  margin: 5px;
}

.profile-data {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.profile-identity {
  flex: 1;
}

.profile-name {
  font-weight: bold;
}

.settings-icon {
  padding: 0.4rem 0 0.1rem 0
}

.profile-avatar {
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  border-radius: 50%;
  background-color: var(--avatar-background-color);
  text-align: center;
  margin-right: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
}

.profile-avatar:hover {
  opacity: 0.6;
}

.version {
  font-size: 0.8rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

@media (max-width: 750px) {
  .profile-data {
    flex-wrap: wrap;
  }

  .profile-data .button {
    width: 100%;
    margin-top: 2rem;
  }

  .version {
    bottom: 5rem;
  }
}