.program-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  padding: 2rem;
  background-color: var(--container-background-color);
  color: var(--primary-font-color);
  border: 1px solid var(--secondary-tint-color);
  flex: 1;
  min-height: 70vh;
}

.program-container .button {
  margin: auto;
  padding: 0.75rem;
  width: calc(100% - 2rem);
}

.publisher-details-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  user-select: none;
}

.publisher-name {
  opacity: 0.6;
  font-weight: bold;
}

.publisher-logo {
  width: 1.75rem;
  height: 1.75rem;
  background-color: #00848b;
  color: #ffffff;
  border-radius: 6px;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
}

.program-name {
  font-size: 2.25rem;
  font-weight: bold;
}

.program-description {
  font-size: 1.1rem;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  line-height: 1.5;
}

.additional-paid-resources {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  opacity: 0.4;
  user-select: none;
}

.additional-paid-resources svg {
  margin-right: 0.25rem;
  width: 1rem;
  height: 1rem;
}

.program-media-container {
  min-width: 20rem;
  flex: 1;
  background-color: #222834;
  margin-right: 2rem;
  border-radius: 0.75rem;
  overflow: hidden;
  object-fit: cover;
}

.program-media-container video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.program-container-wrapper {
  display: flex;
  margin: auto;
  padding: 2rem;
  max-width: 1100px;
}

.publisher-verified {
  display: inline-flex;
}

.publisher-verified svg {
  width: 1.25rem;
  height: 1.25rem;
  opacity: 0.6;
  margin-left: 0.25rem;
}
