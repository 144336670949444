table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
}

tr,
th {
  border-bottom: 1px solid var(--secondary-tint-color);
  text-align: left;
}

tr:last-child {
  border: none;
}

td {
  padding: 1rem 0;
}

td div {
  margin: 0 auto;
}

.people-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}