.space-user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.clickable:hover {
  opacity: 0.6;
  cursor: pointer;
}

.space-user-name-container {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.space-user-item-avatar {
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
  border-radius: 50%;
  text-align: center;
  font-size: 1rem;
  color: var(--primary-font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-color: var(--avatar-background-color);
  font-weight: bold;
  overflow: hidden;
}

.name-container {
  margin-left: 1rem;
  font-weight: bold;
}

@media (max-width: 400px) {
  .space-user-item {
    width: 100%;
  }
}
