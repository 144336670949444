.calendar {
  background-color: var(--container-background-color);
  color: var(--primary-font-color);
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-top: 0;
  overflow: hidden;
  border-radius: 1rem;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.calendar-nav {
  display: flex;
  gap: 1rem;
}

.nav-button {
  background: none;
  border: none;
  color: var(--primary-font-color);
  opacity: 0.6;
  cursor: pointer;
}

.nav-button:hover {
  cursor: pointer;
  opacity: 1;
}

.calendar-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(100% - 50px); /* Adjust based on your header height */
}

.weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 0.5rem;
}

.weekday {
  text-align: center;
  font-weight: 500;
  color: var(--primary-font-color);
}

.days {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: minmax(0, 1fr);
  gap: 0.25rem;
  overflow: hidden;
}

.day {
  padding: 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.day-in-month {
  background-color: var(--primary-background-color);
}

.day-out-month {
  background-color: transparent;
}

.day-number {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  min-height: 1.5rem;
  position: sticky;
  top: 0;
}

.today {
  background-color: var(--primary-action-color);
  color: var(--primary-font-color);
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
}

.event {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  min-height: 1.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--container-background-color);
  border-radius: 15px;
  padding: 0.5rem;
  width: fit-content;
  max-width: 100%;
  transition: outline 0.1s ease-in-out;
  outline: 0px solid transparent;
  user-select: none;
}

.event:hover {
  cursor: pointer;
  outline: 2px solid var(--primary-action-color);
}

.event-content {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.event-title-text {
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: bottom;
}

.size-event-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar .event-title {
  font-size: 0.75rem;
  margin-bottom: 0;
}

.event-title {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  font-size: 1.15rem;
  color: var(--primary-font-color);
}

@media (max-width: 750px) {
  .calendar {
    height: calc(100vh - 250px);
  }
}
