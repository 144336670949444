.radio-range-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  border: 1px solid var(--secondary-tint-color);
  border-radius: 0.75rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: var(--primary-background-color);
  height: 3.5rem;
}

.radio-label {
  padding-left: 0.1rem;
}
