.tag-dropdown {
  position: absolute;
  z-index: 10000000;
  background-color: var(--primary-background-color);
  border-radius: 15px;
  max-height: 300px;
  overflow-y: auto;
}

.tag-dropdown .MuiMenuItem-root {
  margin: 0.25rem !important;
  border-radius: 15px !important;
}

.tag-dropdown .MuiMenuItem-root .MuiListItemText-root .MuiTypography-root {
  font-size: 14px !important;
}

.tag-dropdown .MuiMenuItem-root:hover {
  background-color: var(--container-background-color) !important;
}

.tag-filter-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

@media (max-width: 750px) {
  .tag-dropdown {
    right: -27px;
  }
}
