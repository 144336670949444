.navigation {
  color: var(--primary-font-color);
  font-size: 1.2rem;
  font-weight: bold;
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--subnav-background-color);
  z-index: 10;
  backdrop-filter: blur(10px);
  padding: 0.5rem;
}

.navigation .nav-avatar {
  margin: -1rem 0;
}

.navigation .login {
  font-size: 1rem;
  user-select: none;
  color: var(--primary-font-color);
  text-decoration: none;
}

.connectbetter-logo {
  display: flex;
  align-items: center;
  user-select: none;
}

.connectbetter-logo .logo-mark {
  background-image: url("../../assets/logo.svg");
  width: 2.25rem;
  height: 2.25rem;
  background-size: 100%;
  margin-right: 0.25rem;
}

.tag {
  margin-left: 0.75rem;
  font-size: 14px;
  background-color: var(--container-background-color);
  padding: 0.25rem;
  border-radius: 5px;
}
