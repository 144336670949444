.person-wrapper{
  display: flex;
  justify-content: center;
}

.person-profile-wrapper{
  max-width: 330px;
  width: 100%;
  margin: 1rem;
  margin-top: 0;
}

.person-subnav-wrapper{
  max-width: 525px;
  width: 100%;
  margin: 1rem;
}

@media (max-width: 750px) {
  .person-wrapper{
    display: block;
  }

  .person-subnav-wrapper{
    margin: 2rem 1rem 1rem 1rem;
    max-width: 330px;
  }
}