* {
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  :root {
    --container-background-color: #222834;
    --input-background-color: #14171e;
    --primary-action-color: #4d9ee8;
    --primary-background-color: #12161e;
    --primary-font-color: #ffffff;
    --secondary-font-color: #232832;
    --tertiary-font-color: #9ca3af;
    --primary-tint-color: #222834;
    --secondary-tint-color: rgb(255 255 255 / 15%);
    --tertiary-tint-color: rgb(255 255 255 / 7%);
    --skeleton-tint-color: rgb(255 255 255 / 15%);
    --subnav-background-color: rgb(18 22 30 / 50%);
    --avatar-background-color: #ffae10;
    --secondary-avatar-background-color: rgb(67 72 82);
    --secondary-nav-avatar-background-color: rgb(53 57 64);
    --primary-action-color-highlight: #6b83fc;
  }
}
@media (prefers-color-scheme: light) {
  :root {
    --container-background-color: #ffffff;
    --input-background-color: #f1f0f0;
    --primary-action-color: #4d9ee8;
    --primary-background-color: #f1f0f0;
    --primary-font-color: #232832;
    --secondary-font-color: #ffffff;
    --tertiary-font-color: #9ca3af;
    --primary-tint-color: rgb(57 62 71 / 10%);
    --secondary-tint-color: rgb(194 194 194 / 50%);
    --skeleton-tint-color: rgb(0 0 0 / 15%);
    --subnav-background-color: rgb(242 240 240 / 80%);
    --avatar-background-color: #ffae10;
    --secondary-avatar-background-color: rgb(224 224 224);
    --secondary-nav-avatar-background-color: rgb(218 217 217);
    --primary-action-color-highlight: #6b83fc;
    --tertiary-tint-color: rgb(0 0 0 / 7%);
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-background-color);
  color: var(--primary-font-color);
}

body.app-overflow-hidden {
  overflow: hidden;
}

@media (max-width: 750px) {
  body.app-overflow-hidden {
    overflow: unset;
  }

  .hide-on-mobile {
    display: none !important;
  }
}

hr {
  border: 0.5px solid var(--secondary-tint-color);
}
