.filter-events {
  margin-right: 0.5rem;
}

.filter-events:hover {
  opacity: 1 !important;
}

.calendar-filter-icon {
  border-radius: 0 10px 10px 0 !important;
}

.list-filter-icon {
  border-radius: 0.75rem 0 0 10px !important;
}

.subnav-actions .filter-events-button:last-child {
  margin-left: 0;
}

@media (max-width: 750px) {
  .filter-events-button {
    display: none !important;
  }
}
