.permission-option-container {
  display: flex;
  width: 100%;
}

select {
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
  user-select: none;
  border: 1px solid var(--secondary-tint-color);
}

.permission-content-container {
  margin-left: 1rem;
  user-select: none;
}

.permission-details {
  opacity: 0.6;
}

.permission-details-title {
  font-weight: bold;
}

.permission-details-list {
  margin: 0;
  padding-inline-start: 25px;
}

.permission-option-container:hover {
  cursor: pointer;
}

.permission-button {
  margin-top: 0;
}

.permission-subtitle {
  font-weight: bold;
}
