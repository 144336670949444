.preview-styles {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
}

.post-media-styles {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.post-media-styles img,
.post-media-styles video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
  border-radius: 1rem;
  overflow: hidden;
}
