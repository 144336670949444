.invite-banner {
  background-color: #ffc107;
  padding: 0.75rem 1rem;
  color: #232832;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 1rem;
  justify-content: space-between;
}

.invite-banner-title {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.invite-banner-action .button {
  color: #232832 !important;
  font-weight: 600;
}

.invite-banner svg {
  font-size: 1.2rem;
}

.organization-profile-wrapper {
  background-color: var(--container-background-color);
  border-radius: 15px;
  max-width: 100%;
  border: 1px solid var(--secondary-tint-color);
  overflow: hidden;
}

.organization-profile-content {
  padding: 1.5rem;
}

.organization-profile-wrapper .MuiChip-root {
  margin-top: 0.7rem;
  color: var(--primary-font-color);
  border-color: var(--secondary-tint-color);
}

.organization-profile-wrapper hr {
  border: 0.5px solid;
  opacity: 0.15;
  margin: 1.5rem 0;
}

.organization-profile-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.organization-profile-footer {
  padding: 1.5rem 0 0 0;
  padding-top: 0.5rem;
  bottom: 0px;
  margin-top: 1rem;
}

.organization-profile-name-field {
  font-weight: bold;
  font-size: 1.5rem;
}

.organization-profile-username-field {
  margin-top: 0.25rem;
  opacity: 0.5;
  font-size: 1.1rem;
}

.organization-profile-field {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.organization-profile-field a {
  color: var(--primary-font-color);
  text-decoration: none;
}

.organization-profile-field-metadata {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.3rem;
}

.organization-profile-avatar {
  height: 7rem;
  width: 7rem;
  min-height: 8rem;
  min-width: 8rem;
  border-radius: 50%;
  text-align: center;
  font-size: 2.5rem;
  color: var(--primary-font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-color: var(--avatar-background-color);
  font-weight: bold;
  overflow: hidden;
}

.organization-profile-field svg {
  opacity: 0.5;
  font-size: 1.3rem;
}

.status-container {
  display: flex;
}

.statusText {
  margin: 0.1rem 0 0 0.5rem;
}

.add-data {
  opacity: 0.3;
  user-select: none;
  transition: all 0.12s ease-in-out;
  padding: 0.35rem;
  margin: -0.35rem;
  border-radius: 5px;
  display: block;
}

.add-data:hover {
  opacity: 1;
}
