.space-members-list {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  min-height: 17.7rem;
}

.space-members-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 700px;
  width: 100%;
  border-radius: 15px;
  background-color: var(--container-background-color);
}

.invite-member-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0.6;
  margin: 0.5rem 0;
  width: fit-content;
}

.invite-member-button:hover {
  cursor: pointer;
  opacity: 1;
}

.space-members-role-error {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
  width: 100%;
}