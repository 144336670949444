.nav-label {
  text-transform: uppercase;
  font-weight: bold;
  opacity: 0.5;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  margin-left: 0.75rem;
  user-select: none;
}

.external {
  display: flex;
  justify-content: space-between;
}

.external svg {
  margin-right: 0 !important;
  color: var(--primary-font-color);
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.admin-settings .nav-menu-items a {
  font-size: 1rem;
  margin-bottom: 0.15rem;
}

@media screen and (max-width: 750px) {
  .admin-settings .nav-menu-items {
    flex-direction: row;
    text-align: center;
    overflow-x: auto;
    scrollbar-width: none;
  }

  .admin-settings .nav-menu-items::-webkit-scrollbar {
    display: none;
  }

  .admin-settings .nav-menu-items a {
    flex-direction: row;
    min-width: fit-content;
    font-size: 0.95rem;
    padding: 0.5rem 1rem;
    opacity: 0.5;
    border-radius: 0;
    transition: opacity 0.12s ease-in-out;
    border-bottom: 3px solid transparent;
  }
  
  .admin-settings .nav-menu-items a {
    margin-bottom: 0 !important;
  }

  .admin-settings .nav-menu-items a.active {
    opacity: 1;
    background-color: transparent;
    border-bottom: 3px solid var(--primary-action-color);
  }

  .admin-settings .nav-menu-items a svg {
    margin-right: 0;
  }
}