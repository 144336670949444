.radio-menu-item-container {
  margin-top: 1rem;
}

.radio-menu-item {
  border: 2px solid var(--secondary-tint-color);
  border-radius: 0.75rem;
  display: flex;
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0.15rem;
  margin-bottom: 0.75rem;
  user-select: none;
  align-items: center;
  justify-content: space-between;
}

.radio-menu-item.disabled {
  opacity: 0.5;
}

.radio-menu-item .MuiRadio-root {
  color: var(--primary-font-color);
}

.radio-menu-item.selected {
  border-color: var(--primary-action-color);
}

.radio-menu-item.selected .MuiRadio-root {
  color: var(--primary-action-color);
}

.cancel-and-return {
  font-size: 1rem;
  text-align: center;
  color: var(--primary-font-color);
  opacity: 0.5;
  margin: auto;
}

.cancel-and-return:hover {
  opacity: 0.3;
  cursor: pointer;
}

.program-already-added {
  opacity: 0.5;
  font-size: 0.9rem;
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
}

.program-already-added svg {
  font-size: 1rem;
}
