.iframe {
  width: 100%;
  height: 98%;
  border: none;
}

@media (max-width: 850px) { 
  .iframe {
    height: 100vh;
  }
}