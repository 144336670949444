.name-inputs {
  display: flex;
  justify-content: space-between;
}

.first-name-input {
  margin-right: 5px;
}
.last-name-input {
  margin-left: 5px;
}

.additional-details-button {
  margin: 0;
  padding: 0 !important;
  margin-bottom: 2rem;
  font-size: 0.85rem !important;
  opacity: 0.5;
}

.additional-details-button.open {
  margin-bottom: 0.5rem;
}

.permission-subtitle{
  margin-top: 15px;
  margin-bottom: 5px;
}

.cancel-button {
  border: none;
  background-color:var(--input-background-color);
  padding: 0.5rem 1.5rem;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 0.5rem;
}

.buttons-container {
  display: flex;
}

.right-align-buttons {
  justify-content: flex-end !important;
}

.invite-share-icon:hover {
  cursor: pointer;
  opacity: 0.6;
}

@media (max-width: 750px) {
  .buttons-container {
    width: 100%;
  }
}

.message-banner {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
}

.react-multi-email {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}
.react-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: var(--skeleton-tint-color);
}

.react-multi-email.fill > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  flex: 1;
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
  color: var(--primary-font-color);
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.4em 0.8em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}
