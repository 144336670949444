.organization-settings-avatar{
  width: 10rem;
  height: 10rem;
  font-size: 3rem;
  margin: 1rem 0 1.5rem 0;
  border-radius: 25px;
  background-color: #3059a3;
  color: #fff;
  text-align: center;
  margin-right: 0.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  overflow: hidden;
}

.organization-settings-avatar.with-image {
  background-color: transparent;
  border: 1px solid var(--secondary-tint-color);
}