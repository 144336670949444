.empty-hero {
  display: flex;
  justify-content: center;
  max-width: 550px;
  user-select: none;
  margin: auto;
  margin-top: 20vh;
}

.empty-hero svg {
  width: 8rem;
  height: 8rem;
  min-width: 8rem;
  min-height: 8rem;
}

.empty-hero svg * {
  fill: var(--primary-font-color);
}

.empty-description-container {
  margin-left: 1rem;
}

.empty-title {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}

.empty-description {
  opacity: 0.7;
}

.empty-hero .button {
  margin-top: 2rem;
  padding: 0.35rem 0.85rem;
}