.contentLink {
  color: var(--primary-action-color);
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
  margin-right: 4px;
  font-size: 1rem;
  word-break: break-all;
}

.contentLink:hover {
  opacity: 0.6;
}

.view-more-media-button {
  color: var(--primary-action-color) !important;
  padding: 0 !important;
  margin-top: 0.5rem !important;
}

.media-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  width: 100%;
}

.media-grid.three-items {
  grid-template-columns: repeat(3, 1fr);
}

.media-grid-item {
  position: relative;
  aspect-ratio: 1;
  cursor: pointer;
}

.media-grid-item > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
