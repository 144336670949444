.exercise-list-item.complete {
  border-color: #009688 !important;
  background-color: rgb(0 150 136 / 20%);
}

.exercise-list-item.readyForReview {
  border-color: #009688 !important;
}

.exercise-list-item.scheduled {
  border-color: var(--primary-action-color) !important;
}

.exercise-list-item.animate-complete {
  animation: bounce 0.5s;
}

.exercise-list-item.complete .exercise-checkbox,
.exercise-list-item.complete-readonly .exercise-checkbox {
  border-color: #009688;
  background-color: #009688;
}

.exercise-list-item {
  box-sizing: border-box;
  border-radius: 0.75rem;
  font-size: 1rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
  margin: 0.5rem 0;
  background-color: var(--container-background-color);
}

.action-exercise-list-item {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
}

.exercise-metadata {
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
}

.exercise-checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.exercise-checkbox {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid var(--secondary-tint-color);
  border-radius: 5px;
  margin: 0 0.75rem 0.25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, border-color 0.12s ease-in-out;
}

.exercise-checkbox:hover {
  border-color: #009688;
}

.exercise-checkbox svg {
  color: #ffffff;
}

.exercise-title {
  font-weight: bold;
}

.exercise-parent-title {
  color: var(--primary-font-color);
  opacity: 0.5;
}

.exercise-list-item a {
  color: var(--primary-font-color);
}

.exercise-video-player {
  aspect-ratio: 16/9;
  border: 0;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.exercise-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.75rem;
}

.exercise-link svg {
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.exercise-link:hover svg {
  opacity: 0.75 !important;
}

.exercise-checkbox:active {
  transform: scale(0.9);
}

.exercise-schedule-icon {
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 4px;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.remove-schedule-icon {
  margin-left: 0.25rem;
}

.exercise-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.exercise-actions {
  display: flex;
  align-items: center;
  min-width: 60px;
  justify-content: flex-end;
}

.exercise-details-container:hover .exercise-link svg {
  opacity: 0.25;
}

.exercise-details-container:hover .exercise-schedule-icon {
  opacity: 0.25;
}

.exercise-schedule-icon:hover {
  opacity: 0.75 !important;
  cursor: pointer;
}

.react-datepicker-popper {
  position: fixed !important;
}

.exercise-down-arrow-icon {
  position: absolute;
  top: 10px;
  right: 1px;
  opacity: 0.35;
}

.exercise-down-arrow-icon:hover {
  opacity: 1;
}

.custom-date-picker-input {
  background-color: var(--primary-background-color);
  height: 2.7rem;
  border-radius: 0.75rem;
  border: 1px solid var(--secondary-tint-color);
  top: 10px;
}

.custom-date-picker-input:hover {
  cursor: pointer;
}

.custom-date-picker-input-value {
  padding-top: 10px;
  padding-left: 10px;
}

.exercise-action-dueBy-dropdown {
  position: absolute;
  top: -0.35rem;
  right: 0rem;
  width: 10rem;
  z-index: 10;
}

.exercise-program-title:hover {
  cursor: pointer;
  opacity: 1;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 850px) {
  .exercise-metadata {
    font-size: 0.95rem;
  }
}
