select {
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;
  background-color: var(--input-background-color);
  color: var(--primary-font-color);
  user-select: none;
  border: 1px solid var(--secondary-tint-color);
  margin: 0.5rem 0;
}

select option {
  color: var(--primary-font-color);
}
