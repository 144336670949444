.event-settings-menu-icon:hover {
  cursor: pointer;
  opacity: 0.6;
}

.event-settings-menu .MuiMenuItem-root {
  border-radius: 10px;
  margin: 0 5px;
}

.event-settings-menu .MuiMenuItem-root:hover {
  background-color: var(--container-background-color);
}
