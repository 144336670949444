.leader-container {
  border: 4px solid var(--container-background-color);
  background-color: var(--avatar-background-color);
  border-radius: 25px;
}

.member-container {
  border: 4px solid var(--container-background-color);
  background-color: var(--secondary-avatar-background-color);
  border-radius: 25px;
}

.excess-container {
  border: 4px solid var(--container-background-color);
  background-color: var(--input-background-color);
  border-radius: 25px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.spaces .avatar-display-container {
  margin-left: -0.25rem;
}

.avatar-display-container {
  display: flex;
  margin-bottom: 1rem;
  position: relative;
}

.no-avatar {
  overflow: hidden;
  display: flex;
}

.no-avatar div {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.spaces-avatar {
  overflow: hidden;
  max-height: 2.25rem;
  max-width: 2.25rem;
  border-radius: 50%;
}

.overlap {
  position: absolute;
  transform: translate(-3%, -75%);
  min-width: 2.75rem;
  min-height: 2.75rem;
}

.overlap:nth-child(1) {
  z-index: 1;
}

.overlap:nth-child(2) {
  z-index: 2;
  left: 2rem;
}

.overlap:nth-child(3) {
  z-index: 3;
  left: 4rem;
}

.overlap:nth-child(4) {
  z-index: 4;
  left: 6rem;
}

.overlap:nth-child(5) {
  z-index: 5;
  left: 8rem;
}

.overlap:nth-child(6) {
  z-index: 6;
  left: 10rem;
}

.overlap:nth-child(7) {
  z-index: 6;
  left: 12rem;
}

.upcoming-connection-container {
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.upcoming-connection-container .live-icon {
  font-size: 0.8rem;
  padding: 0.15rem 0.25rem;
  margin: 0;
}
