.billing-container-wrapper {
  max-width: 700px;
  margin: auto;
}

.billing-container {
  display: flex;
}

.invoice-container,
.subscription-container,
.account-credit-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: 700px;
  width: 100%;
  border-radius: 15px;
  padding: 1.5rem;
  background-color: var(--container-background-color);
  overflow-x: auto;
}

.subscription-container {
  margin-right: 1rem;
}

.subscription-container.loading,
.account-credit-container.loading {
  padding-top: 1rem;
}

.subscription-container,
.account-credit-container {
  max-width: calc(350px - 0.5rem);
  height: fit-content;
}

.subscription-container .button,
.account-credit-container .button {
  margin-top: 1.5rem;
}

.billing-amount {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.billing-details-item {
  margin-right: 1rem;
}

.billing-details-item-title {
  opacity: 0.6;
  margin-bottom: 0.25rem;
}

.billing-details-item-value {
  font-size: 1.15rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.billing-details-item-value svg {
  margin-right: 0.25rem;
}

.subscription-container .billing-details-container {
  display: flex;
}

.billing-next-attempt-at {
  opacity: 0.6;
  display: flex;
}

.billing-next-attempt-at svg {
  margin-right: 0.25rem;
}

.subscription-container hr {
  width: 100%;
  margin: 1.25rem 0;
  border: none;
  border-bottom: 1px solid var(--secondary-tint-color);
}

.MuiSkeleton-root {
  background-color: var(--skeleton-tint-color) !important;
}

.stripe-partnership {
  margin-top: 1rem;
  font-size: 0.9rem;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.stripe-partnership svg {
  margin-right: 0.15rem;
  font-size: 1rem;
}

.billing-container-wrapper .MuiAlert-root {
  margin-bottom: 1rem;
  border-radius: 15px;
}

@media (max-width: 1000px) {
  .subscription-container,
  .account-credit-container {
    max-width: 100%;
  }

  .billing-container {
    flex-direction: column;
  }
}