.actions-toggle-label {
  color: var(--primary-font-color);
  font-weight: normal;
  font-size: 1.15rem;
}

.action-toggle-container {
  padding: 0;
}

.daily-checkin-settings-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.daily-checkin-person-icon {
  margin-right: 1rem;
  height: 1.75rem;
}

.daily-checkin-day-select-container {
  background-color: var(--primary-background-color);
  min-height: 3rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 0.75rem;
  font-size: 15px;
}

.day-selected:hover,
.day-unselected:hover {
  cursor: pointer;
  opacity: 0.6;
}

.day-unselected {
  color: var(--secondary-tint-color);
}

@media (max-width: 900px) {
  .daily-checkin-day-select-container {
    flex-direction: column;
    font-size: 24px;
    padding: 1rem;
  }

  .day-selected,
  .day-unselected {
    padding: 1rem;
  }
}
