.spaces-toggle-label {
  color: var(--primary-font-color);
  font-weight: normal;
  font-size: 1.15rem;
}

.space-toggle-container {
  padding: 0;
}

.spaces-wrapper {
  padding-top: 1rem;
  margin: auto;
  max-width: 700px;
}

.card-content-container {
  display: flex;
  justify-content: space-between;
}

.organization-space-settings-container {
  display: flex;
  align-items: center;
}

.edit-connection-toggle {
  margin-bottom: 1rem;
  padding: 0;
}

.admin-space-card {
  display: flex;
  justify-content: space-between;
  background-color: var(--container-background-color);
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 1rem;
  transition: outline 0.1s ease-in-out;
  outline: 0px solid var(--primary-action-color);
  cursor: pointer;
}

.admin-space-card:hover {
  outline: 3px solid var(--primary-action-color);
}

.admin-space-card .space-name {
  font-size: 1.15rem;
  font-weight: bold;
}

.admin-space-card .space-data {
  font-weight: normal;
  margin-top: 0.15rem;
}

.admin-space-card .space-logo {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--skeleton-tint-color);
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin-right: 0.75rem;
}
