.end-connection-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.end-connection-modal-prompt {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.end-connection-modal-actions {
  display: flex;
  justify-content: space-between;
}

.connection-leave-option-container {
  display: flex;
  margin-left: 2rem;
}

.rejoin {
  margin-right: 1rem;
}

.end-description {
  line-height: 24px;
}

.end-connect-primary-action-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 25px;
  width: 100%;
}

.end-connect-primary-action-container .button {
  flex: 1;
}

.end-connect-primary-archive-container {
  margin-bottom: 2rem;
  font-size: 0.9rem;
  opacity: 0.7;
}

.end-connect-primary-archive-link {
  color: var(--primary-action-color);
  text-decoration: underline;
  cursor: pointer;
}

.end-connect-primary-archive-link:hover {
  color: var(--primary-action-color-highlight);
}

.end-connection-modal-content .modal-content {
  padding: 0;
  margin: 0;
}

@media (max-width: 750px) {
  .rejoin {
    margin-right: 0;
  }

  .archive {
    max-width: 8rem;
  }

  .end-connect-primary-action-container {
    min-width: 50%;
  }
}
