.space-nav {
  display: flex;
  gap: 0.5rem;
  border-bottom: 1px solid var(--primary-tint-color);
  align-items: center;
  user-select: none;
}

.space-nav-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-bottom: 3px solid transparent;
  margin-bottom: -1px;
  opacity: 0.6;
  transition: opacity 0.12s ease-in-out;
}

.space-nav-item:hover {
  opacity: 0.6;
  cursor: pointer;
}

.space-nav-item.active {
  opacity: 1;
  border-bottom: 3px solid var(--primary-action-color);
}

.live-events-indicator {
  width: 8px;
  height: 8px;
  background-color: #ff0000;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  margin: 4px;
}
