.person-item {
  display: flex;
  justify-content: flex-start;
}

.person-checkbox {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin: 10px;
  margin-left: 0;
}

.person-name {
  font-size: 1rem;
  font-weight: bold;
}

.person-card-container {
  border-radius: 15px;
  color: var(--primary-font-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
  cursor: pointer;
  background-color: var(--container-background-color);
}

.person-card-container select {
  margin: 0;
  padding: 0.5rem;
}

.inner-person-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.visibility {
  visibility: hidden;
}

.action-items {
  margin-left: 35px;
  border-radius: 5px;
  padding: 8px;
  background-color: var(--secondary-tint-color);
  font-size: .9rem;
}

.action-link {
  color: var(--primary-action-color-highlight);
  text-decoration: underline;
  cursor: pointer;
  padding-left: 10px;
}

.cannot-add-message {
  display: flex;
  align-items: center;
  min-width: 171px;
  cursor: pointer;
}