.people-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  padding: 1rem;
  padding-top: 0.5rem;
  background-color: var(--container-background-color);
  overflow-x: auto;
  height: calc(100vh - 210px);
}

.MuiCheckbox-root {
  color: var(--secondary-tint-color) !important;
}

.MuiCheckbox-root.Mui-checked {
  color: var(--primary-action-color) !important;
}

.last-settings-selected {
  padding-bottom: 10rem;
}

.person-input-container {
  margin: auto;
  width: 100%;
  margin-bottom: 1rem;
}

.person-avatar {
  height: 2.25rem;
  width: 2.25rem;
  min-height: 2.25rem;
  min-width: 2.25rem;
  border-radius: 50%;
  text-align: center;
  font-size: 1rem;
  color: var(--primary-font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-color: var(--avatar-background-color);
  font-weight: bold;
  overflow: hidden;
}

.person-avatar:hover {
  cursor: pointer;
  opacity: 0.6;
}

.person-pagination-wrapper {
  display: flex;
  justify-content: space-between;
}

.page-navigation-container {
  margin-top: 0.5rem;
}

.page-count-container {
  margin: auto;
  display: flex;
}

.page-index-count-container {
  font-weight: bold;
  margin-left: 0.5rem;
}

.settings-icon-open {
  background-color: var(--avatar-background-color);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.2rem 0.1rem 0.1rem 0.3rem;
  max-width: 2.1rem;
  width: 100%;
  margin: 0;
}

.settings-icon-close {
  background-color: var(--avatar-background-color);
  border-radius: 5px;
  padding: 0.2rem 0.1rem 0.1rem 0.3rem;
  max-width: 2.1rem;
  width: 100%;
  margin: 0;
}

.settings-icon-open:hover {
  opacity: 0.6;
}

.settings-icon-close:hover {
  opacity: 0.6;
}

.member-spaces {
  display: flex;
  user-select: none;
  width: fit-content;
  border-radius: 0.75rem;
  transition: background-color 0.12s ease-in-out;
  margin: -0.25rem -0.35rem;
  padding: 0.5rem 0.35rem;
  min-width: 100%;
}

.display-none {
  display: none;
}

.settings-wrapper {
  position: relative;
  z-index: 10;
}

.contact-details-cell {
  display: flex;
  user-select: none;
  border-radius: 0.75rem;
  transition: background-color 0.12s ease-in-out;
  padding: 0.35rem;
  margin: -0.25rem -0.35rem;
}

.contact-details-cell:hover {
  background-color: var(--secondary-tint-color);
  cursor: pointer;
}

.member-spaces:hover {
  background-color: var(--secondary-tint-color);
}

.contact-details-cell .person-avatar {
  margin: 0;
}

.contact-details-container {
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.contact-details-container .contact-email {
  opacity: 0.7;
  font-size: 0.9rem;
  margin: 0;
  margin-top: 0.1rem;
}

.contact-details-container .contact-fullname {
  margin: 0;
}

table.people tr th:last-child,
table.people tr td:last-child {
  width: 1rem;
  padding: 0.5rem 0;
}

table.people tr th:first-child,
table.people tr td:first-child {
  min-width: 18rem;
  padding: 0.5rem 0;
  padding-right: 0.5rem;
}

table.people td {
  padding: 0.5rem;
}

.status-inactive td svg,
.status-inactive td {
  opacity: 0.4;
}

.status-inactive td:last-child {
  opacity: 1;
}

.display-none {
  display: none;
}

@media (max-width: 750px) {
  table.people tr td:last-child,
  table.people tr th:last-child {
    width: 1.5rem;
    padding: 1rem 0;
  }

  .button.secondary {
    padding: 0.5rem 0.5rem;
  }

  table.people tr td:nth-child(2) {
    width: 1.5rem;
  }

  table.people td {
    padding: 0.2rem;
  }

  tr,
  th {
    border-bottom: 1px solid var(--secondary-tint-color);
    text-align: left;
  }

  tr {
    font-size: 0.98rem;
  }

  .person-pagination-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .page-navigation-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 10px 10px 0;
  }

  .page-navigation-container button {
    flex: 1;
    height: 35px;
  }

  .page-select {
    display: none;
  }

  .people-table-container,
  .people-table-container > div {
    height: fit-content !important;
  }
}
