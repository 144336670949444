.review-exercise-list-item {
  box-sizing: border-box;
  border-radius: 0.75rem;
  font-size: 1rem;
  padding: 0.75rem;
  margin: 0.5rem 0;
  display: flex;
  background-color: var(--container-background-color);
  border: 2px solid var(--secondary-tint-color);
}
