.organization-profile-subnav-wrapper {
  max-width: 100%;
  min-height: 8rem;
}

.organization-profile-subnav-headers {
  display: flex;
  margin: 0 0 2rem 0;
  border-bottom: 1px solid var(--container-background-color);
  z-index: 10;
}

.organization-profile-subnav-header-item {
  font-weight: normal;
  margin-bottom: -3px;
  font-size: 1rem;
  padding: 0 0.75rem;
  opacity: 0.6;
  cursor: pointer;
}

.organization-profile-subnav-header-selected {
  margin-bottom: -1px;
  border-bottom: 3px solid var(--primary-action-color);
  font-size: 1rem;
  padding: 0 0.75rem;
  padding-bottom: 0.75rem;
}

@media (max-width: 750px) {
  .organization-profile-subnav-headers {
    position: sticky;
    top: -1rem;
    background-color: var(--primary-background-color);
    padding: 0.75rem 0;
  }
}
