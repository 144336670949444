.playlist-selector {
  max-width: 300px;
  overflow-x: auto;
  gap: 10px;
  padding: 10px;
  max-height: 70vh;
  background-color: var(--primary-background-color);
}

.playlist-card img {
  width: 200px;
  height: 300px;
  object-fit: cover;
  border-radius: 4px;
}

.playlist-card.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.playlist-card.active {
  border: 2px solid var(--primary-action-color);
}

.playlist-card.completed {
  opacity: 0.6;
}

.playlist-card:hover {
  opacity: 0.6;
  cursor: pointer;
}

.playlist-card {
  display: flex;
  justify-content: space-between;
  background-color: var(--container-background-color);
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 1rem;
  border: 2px solid transparent;
}

.playlist-card .playlist-item-name {
  font-size: 1.15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 200px;
}

.playlist-card .publisher-name {
  font-weight: normal;
  margin-top: 0.15rem;
}

.playlist-card .playlist-item-logo {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--skeleton-tint-color);
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin-right: 0.75rem;
}

@media (max-width: 1000px) {
  .playlist-selector {
    max-width: 100%;
    display: flex;
    overflow-x: auto;
    padding: 0;
  }

  .playlist-card {
    min-width: 200px;
    width: 200px;
    flex: 0 0 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .playlist-card .playlist-item-name {
    font-size: 1rem;
    max-width: 100px;
  }

  .playlist-card .publisher-name {
    font-size: 0.8rem;
  }

  .playlist-card .program-subscribed {
    font-size: 0.8rem;
  }

  .playlist-card .playlist-item-logo {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1rem;
    border-radius: 5px;
  }
}
