.space-page-header {
  display: flex;
  padding: 1rem 0;
  align-items: center;
}

.space-page-header svg {
  margin-right: 1.25rem;
  padding-top: 0.3rem;
  color: var(--primary-font-color);
}

.space-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-settings .space-container {
  padding: 0;
}

.app-settings .connection-history-container {
  margin-top: 0;
}

.space-title {
  margin-bottom: 0.1rem;
  font-size: 1.15rem;
  font-weight: bold;
  word-break: break-word;
}

.connection-history-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: 700px;
  width: 100%;
  border-radius: 15px;
}

.space-greeting {
  font-size: 2.5rem;
  margin-bottom: 4rem;
}

.space-current-datetime {
  font-size: 1.1rem;
  opacity: 0.5;
  margin-bottom: 0.75rem;
}

.button.button-new-connect {
  justify-content: left;
  font-size: 1.2rem;
  padding: 1.1rem;
  border-radius: 15px;
  margin-bottom: 2rem;
}

.button.button-new-connect svg {
  margin-right: 0.5rem;
}

.empty-connections {
  margin-bottom: 1rem;
}

.members-display-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.close {
  display: flex;
  align-items: center;
}

.close:hover {
  opacity: 0.6;
  cursor: pointer;
}

.button.secondary.avatar-button {
  justify-content: flex-start !important;
  padding-left: 1rem !important;
  margin-left: 0.5rem;
  display: flex !important;
  width: fit-content !important;
  align-items: center !important;
  padding-left: 0.5rem !important;
}

.avatar-button-member {
  justify-content: flex-start !important;
  margin-left: 0.5rem;
  display: flex !important;
  width: fit-content !important;
  align-items: center !important;
  padding-left: 0.5rem !important;
}

.roles {
  border-top-left-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
}

.secondary-nav-overlap {
  margin-left: -0.3rem;
}

.space-no-connections {
  display: flex;
  justify-content: center;
  padding: 3rem 1rem;
  border-radius: 15px;
  margin-bottom: 2rem;
  font-size: 1.15rem;
  margin-top: 1rem;
  border: 1px solid var(--secondary-tint-color);
}

.page-title .space-name {
  font-size: 1.15rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.space-name-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.space-member-count {
  display: none;
}

.space-actions {
  display: flex;
}

.space-container .subnav-header {
  margin-top: -2px;
}

@media (max-width: 750px) {
  .space-container .subnav-header {
    margin-top: 0.5rem;
  }

  .space-container .page-title {
    margin-left: -0.5rem;
  }

  .space-name-container {
    max-width: calc(100vw - 54px);
    gap: 1rem;
    align-items: center;
  }

  .button.secondary.avatar-button {
    display: none !important;
  }

  .page-title .space-name {
    background-color: var(--tertiary-tint-color);
    border-radius: 0.75rem;
    font-size: 1rem;
    padding: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    user-select: none;
    flex: 1;
    cursor: pointer;
  }

  .page-title .space-member-count {
    font-size: 0.85rem;
    opacity: 0.5;
    font-weight: normal;
    display: block;
  }

  .space-container .subnav-title-container {
    margin-bottom: 0.75rem;
  }
}
