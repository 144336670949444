.person-spaces-footer{
  padding: 1.5rem 0;
  padding-top: 0.5rem;
  bottom: 0px;
  background-color: var(--primary-background-color);
  display: flex;
  margin-top: 2rem;
}

.organization-profile-space-card-container{
  border-radius: 15px;
  color: var(--primary-font-color);
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding: 1rem;
  width: 100%;
  background-color: var(--container-background-color);
  transition: outline 0.1s ease-in-out;
  outline: 0px solid var(--primary-action-color);
}

.organization-profile-space-card-container:hover {
  outline: 3px solid var(--primary-action-color);
  cursor: pointer;
}