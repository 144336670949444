.person-notes-footer {
  background-color: var(--primary-background-color);
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0 1rem 0;
  align-items: center;
  padding: 0 0.5rem;
}

.person-notes-container {
  border-radius: 15px;
  background-color: var(--container-background-color);
  padding: 0.75rem;
  min-height: 10rem;
}

.person-notes-container p:first-child {
  margin-top: 0;
}

.person-notes-icon-container {
  cursor: pointer;
  margin-right: 0.5rem;
}

.person-notes-icon-container:hover {
  opacity: 0.6;
}
