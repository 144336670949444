.achievement-list {
  display: flex;
  margin: 2rem 0;
}

.achievement {
  width: 10rem;
  height: 10rem;
  background-color: rgb(155 103 69 / 25%);
  padding: 1rem;
  border: 1px solid #9b6745;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  border-radius: 15px;
  font-size: 1.25rem;
  flex-direction: column;
}

.achievement-label {
  font-size: 1rem;
  margin-top: 0.25rem;
}