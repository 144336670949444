.action-button {
  height: 2rem;
}

.action-button:hover {
  opacity: 0.6;
}

.action-button-container {
  display: flex;
  align-items: center;
}

.settings-items-wrapper {
  position: relative;
}
