.reports-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weekly-history-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  
  border-radius: 15px;
  padding: 0 1rem;
  background-color: var(--container-background-color);
}

.weekly-history-item {
  margin-bottom: 1rem;
}
  
.weekly-history-item a {
  display: block;
  cursor: pointer;
  padding: 1rem 0;
  color: var(--primary-action-color);
  text-decoration: none;
  font-weight: bold;
  border-bottom: 1px solid var(--secondary-tint-color);
}
  
.weekly-history-item a:last-child {
  border-bottom: none;
}