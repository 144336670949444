.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-action-color);
}

.card-subtitle {
  margin-top: .25rem;
  opacity: 0.6;
}

.card-header{
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 2rem;
  height: 2rem;
  border-radius: 6px;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
  background-color: #d23e3e;
}

.card-container {
  user-select: none;
}