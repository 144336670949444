.register-container .title {
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: center;
}

.register-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  padding: 2rem;
  background-color: var(--container-background-color);

  color: var(--primary-font-color);
  width: 100%;
  max-width: 400px;
  margin: 1rem auto;
}

.register-container-wrapper {
  display: flex;
  justify-content: center;
  min-height: calc(100dvh - 60px);
  flex-direction: column;
  padding: 1rem;
}

.register-container-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--primary-font-color);
}

.register-container .button {
  margin-top: 1rem;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 750px) {
  .login-container {
    padding: 1.5rem;
  }
}
