.avatar-uploader-container{
  margin-top: 1rem;
}

.avatar-uploader-container .button-space {
  display: flex;
}

.avatar-uploader-container .button-space .button {
  margin-right: 1rem;
}

.avatar-uploader {
  width: 10rem;
  height: 10rem;
  margin: 1rem 0 1.5rem 0;
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid var(--secondary-tint-color);
}

.avatar-uploader.as-user {
  border-radius: 50%;
}