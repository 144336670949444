.organization-settings-container {
  max-width: 700px;
  margin: auto;
}

.organization-settings-container .profile-fields-container {
  padding: 1.5rem;
}

.organization-settings-container .space-select-container,
.organization-settings-container
  .daily-checkin-form-container
  .profile-setting-container {
  padding: 1rem;
}

.organization-settings-container .subnav-header:first-child {
  margin-top: -2rem;
}

.integrations-hero-wrapper {
  border: 1px solid var(--tertiary-tint-color);
  border-radius: 15px;
  padding-bottom: 7rem;
}

.integrations-hero-wrapper .empty-hero {
  margin-top: 7rem;
}

@media screen and (max-width: 750px) {
  .organization-settings-container .subnav-header:first-child {
    margin-top: 0;
  }

  .organization-settings-container .subnav-header:first-child,
  .organization-settings-container .subnav-header {
    min-height: auto;
  }

  .organization-settings-container .subnav-header .title-container h1 {
    font-size: 1rem;
  }

  .organization-settings-container .org-nav-settings,
  .integrations-hero-wrapper {
    margin-top: 1rem;
  }
}
