.settings-container {
  position: absolute;
  background-color: var(--primary-background-color);
  padding: 0;
  right: 0;
  border-radius: 0.75rem;
  box-shadow: 0px 5px 12px var(--primary-background-color);
  z-index: 10;
  top: 0.25rem;
  border: 1px solid var(--secondary-tint-color);
  min-width: 200px;
}

.status-button {
  display: flex;
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  user-select: none;
  font-size: 1rem;
  align-items: center;
  transition: 0.2s opacity ease;
  cursor: pointer;
}

.status-button:hover {
  opacity: 0.6;
}

.dropdown.people-settings {
  top: -0.5rem !important;
  right: 0;
}

.dropdown .MuiMenuItem-root .danger {
  color: #d23e3d;
}
