.apply-promo-credit-modal {
  max-width: 500px;
  width: 100%
}

.footer {
  margin-top: 0.5rem;
}

.promotion-apply-error {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
}