.connection header {
  padding: 1rem;
  padding-right: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 50px;
  height: 50px;
  position: sticky;
  top: 0;
  background-color: var(--primary-background-color);
  z-index: 10001;
}

.connection .MuiTab-root {
  color: var(--primary-font-color);
  text-transform: capitalize;
}

.connection .users-in-connection {
  display: flex;
  margin-right: 0.5rem;
  max-width: 200px;
  overflow: hidden;
  user-select: none;
  gap: 0.25rem;
}

.connection .actions {
  display: flex;
}

.left {
  width: calc(70vw - 2rem);
}

.connection .left,
.connection .right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.connection header .space-name {
  font-size: 1.1rem;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.connection header .actions .button {
  margin-right: 0.5rem;
  padding: 0 0.25rem !important;
}

.connection header .actions .button:last-child {
  margin-right: 0;
}

.connection header .nav-avatar {
  min-width: 1.75rem;
  width: 1.75rem;
  min-height: 1.75rem;
  height: 1.75rem;
  font-size: 1rem;
  margin-right: 0;
}

.addon-container .addon-header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  position: sticky;
  top: 0;
  background-color: var(--container-background-color);
  z-index: 1;
  user-select: none;
  cursor: grab;
  box-shadow: 0px 10px 15px var(--container-background-color);
}

.addon-container .addon-content {
  flex: 1;
  overflow: auto;
  padding: 1rem;
}

.addon-container .addon-header svg {
  width: 1.1rem;
  height: 1.1rem;
}

.addon-container .addon-header h1.title {
  font-size: 1rem;
  margin: 0;
  display: flex;
  align-items: center;
}

.addon-container .addon-header h1.title svg {
  margin-right: 0.25rem;
  opacity: 0.6;
  cursor: grab;
}

.addon-header .button.icon {
  height: 1.5rem;
  width: 2rem;
  background-color: transparent;
  margin: -0.5rem;
  margin-top: -0.25rem;
  opacity: 0.5;
  border: none;
}

.addon-header .button.icon:hover {
  opacity: 1;
  background-color: var(--tertiary-tint-color);
}

.addon-header .button.icon.disabled:hover {
  opacity: 0.5;
  background-color: transparent;
  cursor: default;
}

.addon-header .actions {
  opacity: 0;
}

.addon-container:hover .addon-header .actions {
  opacity: 1;
}

.addon-header .actions .button {
  margin-right: 0.25rem;
}

.addon-header .actions .button:last-child {
  margin-right: -0.5rem;
}

a.call svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.25rem;
}

.connection-status {
  border: 1px solid var(--secondary-avatar-background-color);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
  border-radius: 5px;
  user-select: none;
  padding: 0.3rem 0.5rem;
  min-width: max-content;
}

.connection-status-live {
  background-color: #d23e3e;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 1rem;
  margin-right: 0.25rem;
  font-size: 0.85rem;
  padding: 0.2rem;
  border-radius: 5px;
  user-select: none;
}

.mobile-addons-tabs {
  display: none;
}

.space-purpose {
  margin: 0 1rem;
  position: relative;
}

.space-purpose:hover {
  cursor: pointer;
}

.space-purpose-dropdown {
  font-weight: 500;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 0.75rem;
  transition: all 0.2s ease;
  border: 1px solid transparent;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1.8rem;
  word-break: break-all;
  margin-top: -0.15rem;
}

.space-purpose-dropdown:hover {
  border: 1px solid var(--secondary-tint-color);
  background-color: var(--input-background-color);
}

.space-purpose:hover .space-purpose-dropdown {
  display: block;
  top: 50%;
  position: absolute;
  z-index: 100;
  top: 25px;
  left: 20px;
  max-width: 200px;
  border-radius: 15px;
  padding: 0.5rem;
  border: 1px solid var(--secondary-tint-color);
  background-color: var(--primary-background-color);
}

.connection-left-sub-header-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.connection-date {
  opacity: 0.7;
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 1.8rem;
}

@media (max-width: 600px) {
  .space-purpose:hover .space-purpose-dropdown {
    top: 75%;
  }

  .connection .right {
    margin-left: 0;
  }

  .connection-status {
    margin-left: 0;
  }
}

@media (min-width: 850px) {
  .addons {
    min-width: 400px;
    max-width: 30vw;
    width: 100%;
    max-height: calc(100dvh - 50px);
    overflow: auto;
    position: absolute;
    padding-right: 1rem;
    right: 0;
  }

  .addons .addon-wrapper {
    width: 100%;
    border-radius: 0.75rem;
    position: relative;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    height: 350px;
    display: flex;
    flex-direction: column;
  }

  .addons .addon-container {
    width: 100%;
    height: 100%;
    border-radius: 0.75rem;
    position: absolute;
    background-color: var(--container-background-color);
    margin-bottom: 1rem;
    overflow: hidden;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
  }

  .addons .addon-wrapper.pinned.fullscreen {
    z-index: 100000;
  }

  .addons .addon-wrapper.pinned {
    position: fixed;
    left: 1rem;
    top: 50px;
    width: calc(70vw - 2rem);
    height: calc(100dvh - (50px + 1rem));
    z-index: 1000;
  }

  .addons .addon-container.pinned {
    position: fixed;
    left: 1rem;
    top: 50px;
    width: calc(70vw - 2rem);
    height: calc(100dvh - (50px + 1rem));
    z-index: 100001;
  }

  .addon-container.pinned .addon-header {
    cursor: default;
  }

  .addons .addon-container.fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100dvh;
    z-index: 100002;
    border-radius: 0;
  }

  .addon-container.fullscreen .addon-header {
    cursor: default;
  }

  .addon-container:hover {
    border: 1px solid var(--secondary-tint-color);
  }

  .addon-container.fullscreen,
  .addon-container.fullscreen:hover {
    border: 1px solid var(--container-background-color);
  }
}

@media screen and (max-width: 1332px) {
  .addons .addon-container.pinned,
  .addons .addon-wrapper.pinned {
    width: calc(100vw - (400px + 2rem));
  }

  .addons .addon-container.fullscreen {
    width: 100vw;
  }

  .space-purpose-dropdown {
    max-width: 500px;
  }
}

@media (max-width: 850px) {
  .addons {
    padding: 0;
    width: 100%;
  }

  .mobile-addons-tabs {
    display: block;
    border-bottom: 1px solid var(--secondary-tint-color);
  }

  .addon-wrapper {
    display: none;
  }

  .addons .active-on-mobile .addon-wrapper,
  .addons
    .active-on-mobile
    .addon-wrapper.pinned
    .addons
    .active-on-mobile
    .addon-container,
  .addons .active-on-mobile .addon-container.pinned {
    display: block;
    min-height: calc(100dvh - 9rem);
    position: static;
    width: 100vw;
    border-radius: 0;
    background-color: var(--container-background-color);
    overflow: hidden;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
  }

  .connection-date {
    display: none;
  }

  .connection-status-live {
    display: none;
  }

  .share-connect {
    display: none !important;
  }

  .addon-container .addon-header .actions {
    display: none;
  }

  .space-purpose-dropdown {
    max-width: 150px;
    display: none;
  }
}
