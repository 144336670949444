.post-feed_post-header-info {
  display: flex;
  align-items: center;
}

.post-feed_post-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--tertiary-font-color);
  font-size: 0.875rem;
  margin-left: -0.35rem;
  margin-top: 0.5rem;
  user-select: none;
}

.post-feed_action-count {
  margin-right: 12px;
  font-size: 0.9rem !important;
}

.post-feed_group-tag {
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  margin-left: 0.5rem;
}

.post-feed_group-tag-text {
  font-size: 0.9rem !important;
  margin-left: 4px !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post-feed_post-time {
  color: var(--primary-font-color) !important;
  opacity: 0.6;
  font-size: 14px !important;
  margin-left: 8px !important;
}
.post-feed_post-header-name {
  font-size: 15px !important;
  color: var(--primary-font-color) !important;
}

.post-edit-input {
  border-radius: 0.5rem !important;
  margin-bottom: 8px;
  color: var(--primary-font-color) !important;
  background-color: var(--primary-background-color) !important;
}

.post-feed_post {
  border-radius: 0.75rem;
  background-color: var(--container-background-color);
  padding: 1rem;
  padding-bottom: 0.25rem;
  width: 100% !important;
  min-width: 100% !important;
  display: flex;
  overflow: hidden;
}

.post-feed_post-content {
  width: calc(100% - 44px);
  margin-top: -0.3rem;
}

.post-feed_post-content p:first-child {
  margin-top: 0;
}

.post-feed_post-content p:last-child {
  margin-bottom: 0;
}

.post-feed_post-content p {
  word-break: break-word;
}

.post-avatar {
  height: 2.25rem;
  width: 2.25rem;
  min-width: 2.25rem;
  min-height: 2.25rem;
  border-radius: 50%;
  background-color: var(--avatar-background-color);
  text-align: center;
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
}

.post-avatar:hover {
  cursor: pointer;
  opacity: 0.6;
}

.post-controls {
  opacity: 0.25;
}

.post-controls:hover {
  cursor: pointer;
  opacity: 0.8;
}

.reaction-comment-icons:hover {
  cursor: pointer;
  opacity: 0.6;
}

.post-edit-form .MuiInputBase-root {
  color: var(--primary-font-color) !important;
  background-color: var(--primary-background-color) !important;
  border-radius: 0.5rem !important;
  border: 1px solid var(--secondary-tint-color);
}

.post-edit-form
  .MuiInputBase-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--primary-action-color) !important;
}

.post-edit-form-button {
  background-color: var(--primary-action-color) !important;
  color: var(--primary-font-color) !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.post-edit-form-button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.post-edit-form-cancel-button {
  color: var(--primary-action-color) !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.post-edit-form-cancel-button:hover {
  opacity: 0.6;
}
