.completed-container {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid var(--secondary-tint-color);
  padding: 1rem 0;
}

.action-description {
  word-break: break-word;
}

@media (max-width: 700px) {
  .action-description {
    margin-right: 0.25rem;
  }
}