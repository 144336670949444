.trial-banner {
  width: 100%;
  height: 2.5rem;
  background-color: var(--primary-action-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  font-weight: 600;
}

.trial-content {
  display: flex;
  align-items: center;
}


.payment-button {
  margin-left: 1rem;
  height: 1.5rem;
  font-size: 0.75rem;
  background-color: var(--primary-font-color) !important;
  color: var(--secondary-font-color) !important;
}

.trial-banner .MuiSvgIcon-root:hover {
  cursor: pointer;
  opacity: 0.6;
}

@media (max-width: 750px) {
  .trial-banner {
    position: fixed;
    top: 0;
    font-size: 12px !important;
    z-index: 1000;
  }

  .payment-button {
    font-size: 12px !important;
  }

  .trial-text {
    width: 70%;
  }
}