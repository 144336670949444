.ProseMirror p.is-editor-empty:first-child::before {
  color: var(--primary-font-color);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  opacity: 0.5;
  pointer-events: none;
}

.ProseMirror {
  padding: 0.75rem;
  outline: none;
  min-height: 100%;
}

.ProseMirror a {
  color: var(--primary-font-color);
}

.ProseMirror * {
  max-width: 700px;
  margin: auto;
}

.ProseMirror p, a {
  font-size: 1.1rem;
}

.ProseMirror h1 {
  font-size: 2rem;
}

.ProseMirror hr {
  border: 1px solid var(--secondary-tint-color);
  margin: 1rem auto;
}

.ProseMirror *:first-child {
  margin-top: 0.5rem !important;
}

.addon-container .editor {
  flex: 1;
}

.addon-container.notes .addon-content {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.addon-container.notes {
  padding: 0;
  flex-direction: column;
  display: flex;
  padding: 0 !important;
}

.toolbar {
  padding: 0.5rem 0.75rem;
  border-top: 1px solid var(--tertiary-tint-color);
  position: sticky;
  bottom: 0;
  background-color: var(--container-background-color);
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.toolbar .last-edited {
  opacity: 0.5;
  font-size: 0.9rem;
  align-items: center;
  padding-bottom: 0.35rem;
  display: flex;
}

.toolbar .toolbar-icons {
  display: flex;
  align-items: center;
}

.toolbar .toolbar-icons .icon {
  opacity: 0.5;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 5px;
  margin: -0.5rem;
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar .toolbar-icons .icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.toolbar .toolbar-icons .icon:hover {
  opacity: 1;
  background-color: var(--tertiary-tint-color);
}

.toolbar .toolbar-icons .icon.active {
  opacity: 1;
  background-color: var(--primary-background-color);
}

.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  user-select: none;
  position: relative;
  word-break: normal;
}

.collaboration-cursor__caret:hover .collaboration-cursor__label,
.collaboration-cursor__label:hover {
  opacity: 1;
  pointer-events: all;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  pointer-events: none;
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4rem;
  user-select: none;
  white-space: nowrap;
}

@media screen and (max-width: 850px) {
  .ProseMirror {
    min-height: calc(100dvh - 12rem)
  }
}