.marketplace-container {
  display: grid;
  margin: 0 auto;
  max-width: 850px;
}

.marketplace {
  padding: 1rem;
  padding-top: 0;
  display: block;
}

.programs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 2rem;
}

.public-program-card-container {
  background-color: var(--container-background-color);
  padding: 1rem;
  border-radius: 15px;
  max-width: 400px;
  width: 100%;
  height: 22rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--primary-font-color);
  flex-basis: 100%;
  margin: 0 0.25rem 0.5rem 0.25rem;
}

.public-program-button {
  width: 4rem;
  height: 2rem;
  margin: auto auto 0.5rem auto;
}

.public-remove-program-button {
  width: 6rem;
  height: 2rem;
  margin: auto auto 0.5rem auto; 
}

.public-program-name {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.disabled-button:hover {
  cursor: default;
  opacity: 100% !important;
}

.public-program-version {
  font-weight: 600;
  margin: 0.5rem 0 1rem;
}

.public-program-description {
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 1rem;
}

.program-search {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.program-input {
  margin-left: .5rem;
  margin-right: .5rem;
}

.public-program-publisher-name {
  font-weight: normal;
  margin-top: 0.15rem;
}

@media (max-width: 750px) {
  .public-program-card-container {
    max-width: 100%;
  }

  .programs-container {
    display: block
  }

  .program-input {
    margin-right: 0;
  }
}