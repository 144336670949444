.space-card-container-modal {
  border-radius: 15px;
  color: var(--primary-font-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem 0;
  width: 100%;
  background-color: var(--container-background-color);
}

.card-wrapper {
  max-width: 400px;
  word-break: break-word;
}
  
.space-card-container-modal select {
  margin: 0;
  padding: 0.5rem;
}
  
.inner-space-card-container-modal {
  display: flex;
  justify-content: space-around;
}

.remove-checkbox {
  visibility: hidden;
}

.space-program-access-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--primary-font-color)
}

.inner-space-card-container-modal .card-title {
  font-size: 1.25rem;
  color: var(--primary-font-color);
}

