.invite-people-modal {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.invite-people-modal .remove-button {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.invite-people-modal .add-person-button {
  padding: 0.25rem 0;
  margin-left: -0.25rem;
}

.invite-people-modal .modal-footer {
  margin-top: 0;
  justify-content: space-between;
}

.invite-people-modal input {
  margin: 0;
}

.invite-link-section,
.invite-people-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.link-input-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.person-inputs {
  display: flex;
  gap: 8px;
  align-items: center;
}

.remove-button {
  flex-shrink: 0;
  color: var(--primary-font-color) !important;
  background-color: var(--container-background-color) !important;
  opacity: 0.6;
}

.remove-button:hover {
  cursor: pointer;
  opacity: 1;
}

.add-person-button {
  align-self: flex-start;
  color: var(--primary-action-color) !important;
  background-color: var(--container-background-color) !important;
}

.add-person-button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px;
}

.invite-error-message {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: rgb(189 25 48);
  background: #de8d98;
  border-radius: 5px;
  border: 1px solid rgb(189 25 48);
  padding: 0.5rem;
  font-weight: bold;
}

.invite-error-input {
  border-color: rgb(189 25 48);
}

.disabled-button {
  opacity: 0.6;
}

.disabled-button:hover {
  opacity: 0.6 !important;
}

@media (max-width: 750px) {
  .invite-people-modal .modal-footer {
    flex-direction: column;
  }
}
