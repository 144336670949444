.event-section {
  max-width: 700px;
  margin-bottom: 2rem !important;
  margin: auto;
}

.event-section .event-items .event-item {
  border: none;
}

.event-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.event-item {
  background-color: var(--container-background-color);
  color: var(--primary-font-color);
  border-radius: 1rem;
  padding: 1.25rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--secondary-tint-color);
  position: relative;
  overflow: hidden;
}

.event-info {
  flex-grow: 1;
  overflow: hidden;
  cursor: pointer;
  padding-top: 0.25rem;
  display: flex;
  text-overflow: ellipsis;
  gap: 0.5rem;
  align-items: center;
}

.event-section .event-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 100px);
  margin-bottom: 0;
  font-size: 1.15rem;
}

.event-info .event-title {
  word-break: break-all;
  padding-right: 6rem;
}

.event-space {
  display: flex;
  align-items: center;
  width: fit-content;
  color: var(--tertiary-font-color);
  font-size: 1rem;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  margin-right: 0.25rem;
}

.event-meta {
  display: flex;
  gap: 1rem;
  align-items: center;
  opacity: 0.8;
}

.event-meta .event-meta-item {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.event-location {
  max-width: 100%;
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.location-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.event-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.connection-live {
  text-transform: uppercase;
  color: #fff;
  background-color: #d23e3d;
  display: inline-block;
  padding: 0.15rem 0.25rem;
  border-radius: 5px;
  font-size: 0.7rem;
  user-select: none;
  font-weight: bold;
}

.event-buttons {
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  display: flex;
  gap: 0.5rem;
}

.event-buttons .button {
  padding: 0.35rem 0.95rem;
}

.events-list {
  margin-top: -1rem;
}

.event-space {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  width: fit-content;
  color: #9ca3af;
  font-size: 0.875rem;
  justify-content: space-between;
}

.event-location {
  margin-top: 0.5rem;
  /* width: fit-content; */
  display: flex;
  align-items: center;
  opacity: 0.6;
  max-width: 50%;
  text-overflow: ellipsis;
}

.event-section .MuiAccordion-root {
  margin-top: 0 !important;
  max-width: 700px;
  width: 100%;
}

.event-section .MuiTypography-root {
  color: var(--primary-font-color) !important;
  font-weight: 500 !important;
}

.event-section .MuiAccordionSummary-root {
  min-height: 3rem !important;
  background-color: var(--container-background-color) !important;
  border-radius: 15px !important;
}

.event-section .MuiAccordionSummary-root.Mui-expanded {
  border-radius: 15px !important;
  min-height: 3rem !important;
}

.event-section .MuiPaper-root {
  background-color: transparent !important;
}

.event-section .MuiAccordionDetails-root {
  padding: 0 !important;
  background-color: var(--primary-background-color);
}

@media (max-width: 750px) {
  .event-section {
    max-width: 100%;
    padding: 0rem;
    margin: auto;
  }
}
