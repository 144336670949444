.program-card {
  display: flex;
  justify-content: space-between;
  background-color: var(--container-background-color);
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 1rem;
}

.playlist-card {
  display: flex;
  background-color: var(--container-background-color);
  justify-content: space-between;
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 1rem;
}

.program-card .program-name,
.playlist-card .program-name {
  font-size: 1.15rem;
}

.program-card .publisher-name,
.playlist-card .publisher-name {
  font-weight: normal;
  margin-top: 0.15rem;
}

.program-card .program-logo,
.playlist-card .program-logo {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--skeleton-tint-color);
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin-right: 0.75rem;
}

.program-subscribed {
  display: flex;
  align-items: center;
  margin: 0.25rem 0 0 0.25rem;
}

.program-subscribed svg {
  margin-right: 0.25rem;
}
