.people-gallery-container {
  max-width: 700px;
  margin: auto;
  width: 100%;
}

.people-gallery-container input {
  margin-top: 0;
}

.people-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 0.5rem;
}

.person-card-avatar {
  flex: 1;
}

.person-card-avatar .avatar {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5rem;
  font-weight: bold;
  color: #ffffffce;
}

.person-card {
  border-radius: 15px;
  color: var(--primary-font-color);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-height: 14rem;
  background-color: var(--container-background-color);
  user-select: none;
  transition: outline 0.1s ease-in-out;
  outline: 0px solid var(--primary-action-color);
  overflow: hidden;
  border: 2px solid var(--primary-background-color);
  margin: -2px;
}

.person-card:hover {
  outline: 3px solid var(--primary-action-color);
  border: 2px solid var(--primary-background-color);
}

.person-card-details {
  padding: 1rem;
}

.person-card-details-title {
  font-size: 1.1rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 950px) {
  .people-cards {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }

  .person-card-avatar .avatar {
    font-size: 3rem;
  }

  .person-card-details-title {
    font-size: 1rem;
  }
}

@media (max-width: 450px) {
  .people-cards {
    grid-template-columns: repeat(2, minmax(125px, 1fr));
  }

  .person-card {
    min-height: 10rem;
  }

  .person-card-avatar .avatar {
    font-size: 2.5rem;
  }

  .person-card-details-title {
    font-size: 0.9rem;
  }

  .person-card-details {
    padding: 0.75rem;
  }
}
