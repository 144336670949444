.spaces-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  padding: 1rem;
  padding-top: 0.5rem;
  background-color: var(--container-background-color);
  overflow: auto;
  height: calc(100vh - 245px);
}

table.spaces-table tr th:last-child,
table.spaces-table tr td:last-child {
  width: 1rem;
  padding-right: 0;
}

table.spaces-table tr th:first-child,
table.spaces-table tr td:first-child {
  min-width: 18rem;
  padding: 0.5rem 0;
  padding-right: 0.5rem;
}

table.spaces-table td {
  padding: 0.81rem 0;
  padding-right: 0.5rem;
}

.status-archived td svg,
.status-archived td {
  opacity: 0.4;
}

.status-archived td:last-child {
  opacity: 1;
}

.display-none {
  display: none;
}

.space-details-cell {
  display: flex;
  user-select: none;
  border-radius: 0.75rem;
  transition: background-color 0.12s ease-in-out;
  padding: 0.35rem;
  margin: -0.25rem -0.35rem;
}

@media (max-width: 750px) {
  table.spaces-table tr td:last-child,
  table.spaces-table tr th:last-child {
    width: 1.5rem;
    padding: 1rem 0;
  }

  .button.secondary {
    padding: 0.5rem 0.5rem;
  }

  table.spaces-table tr td:nth-child(2) {
    width: 1.5rem;
  }
  
  table.spaces-table td {
    padding: 0.2rem;
  }

  tr,
  th {
    border-bottom: 1px solid var(--secondary-tint-color);
    text-align: left;
  }

  tr {
    font-size: 0.98rem;
  }

  .person-pagination-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .page-navigation-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 10px 10px 0;
  }

  .page-navigation-container button {
    flex: 1;
    height: 35px;
  }

  .page-select {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .spaces-table-container {
    overflow-x: auto;
  }
}