.program-prestige-display {
  margin-top: 1rem;
}

.program-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.program-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 0.75rem;
  text-align: center;
  background-color: var(--container-background-color);
}

.program-prestige-wrapper {
  background-color: var(--primary-background-color);
}

.program-prestige-circle {
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-font-color);
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 1100px) {
  .program-grid {
    grid-template-columns: 1fr;
  }
}
